const postLoginRouteKey = 'postLoginRoute';

export const SessionStorageService = {
    getPostLoginUrl: () => {
        return sessionStorage.getItem(postLoginRouteKey)
    },

    setPostLoginUrl: (value) => {
        sessionStorage.setItem(postLoginRouteKey, value);
    },

    removePostLoginUrl: () => {
        sessionStorage.removeItem(postLoginRouteKey);
    },
    getSessionValue: (key) => {
        return sessionStorage.getItem(key)
    },

    setSessionValue: (key, value) => {
        sessionStorage.setItem(key, value);
    },

    removeSessionValue: (key) => {
        sessionStorage.removeItem(key);
    }
};