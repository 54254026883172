import { createStore, combineReducers } from 'redux';
import storageSession from 'redux-persist/lib/storage/session';
import { persistReducer } from 'redux-persist';
import { reducer as oidcReducer } from "redux-oidc";
import { JobTypes, DefaultConfigs, RFQDetails, CompletedSteps, BomFile, Client, PCBNetQuoteDetails } from '../redux/reducers/index';


const reducers = {
    JobTypes: JobTypes,
    DefaultConfigs: DefaultConfigs,
    RFQDetails: RFQDetails,
    CompletedSteps: CompletedSteps,
    BomFile: BomFile,
    oidc: oidcReducer,
    Client: Client,
    PCBNetQuoteDetails: PCBNetQuoteDetails
};

const appReducer = combineReducers(reducers);

const rootReducer = (state, action) => {

    // Clear all store data
    // if (action.type === CLEAR_STORE) {
    //     state = undefined;
    // }

    if (action.type === "redux-oidc/SESSION_TERMINATED" || action.type === "redux-oidc/USER_SIGNED_OUT") {
        state = undefined;
    }

    if (action.type == 'redux-oidc/USER_FOUND' && state.oidc.user != undefined &&
        action.payload.profile.EmailConfirmed.toLowerCase() == 'true' && state.oidc.user.profile.EmailConfirmed.toLowerCase() == 'false') {
        state.oidc.user = { ...action.payload };
    }

    return appReducer(state, action)
}

const persistConfig = {
    key: 'root',
    storage: storageSession
};


const persistedReducer = persistReducer(persistConfig, rootReducer)

export const configureStore = () => createStore(persistedReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());