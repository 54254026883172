import { useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { DATA_CONSTANT } from "../../constants/DataConstant";

const usePageViews = () => {
    const location = useLocation();

    useEffect(() => {

        if (DATA_CONSTANT.IsGoogleAnalyticsEnabled && window.ga && (typeof window.ga === 'function')) {
            window.ga('set', 'page', location.pathname + location.search);
            window.ga('send', 'pageview');
        }

    }, [location])
}

export default usePageViews;