export const JARGON_CONSTANT = {
    keys: {
        HomePageTitle: 'HomePageTitle',
        HomePageSubTitle: 'HomePageSubTitle',
        NextButton: 'NextButton',
        RfqPageTitle: 'RfqPageTitle',
        BasicDetailSectionTitle: 'BasicDetailSectionTitle',
        QuantitySectionTitle: 'QuantitySectionTitle',
        QuantitySectionSubTitle1: 'QuantitySectionSubTitle1',
        QuantitySectionSubTitle2: 'QuantitySectionSubTitle2',
        QuantitySectionDescription: 'QuantitySectionDescription',
        FileUploadSectionTitle: 'FileUploadSectionTitle',
        FileUploadSectionSubTitle1: 'FileUploadSectionSubTitle1',
        FileUploadSectionSubTitle2: 'FileUploadSectionSubTitle2',
        MapBOMButton: 'MapBOMButton',
        LaborPageTitle: 'LaborPageTitle',
        AssemblyDetailPageTitle: 'AssemblyDetailPageTitle',
        CustomQuotePageTitle: 'CustomQuotePageTitle',
        CustomQuotePageSubTitle: 'CustomQuotePageSubTitle',
        CustomQuotePageDescription: 'CustomQuotePageDescription',
        CustomQuoteUploadFileSectionTitle: 'CustomQuoteUploadFileSectionTitle',
        CustomQuoteContactDescription1: 'CustomQuoteContactDescription1',
        CustomQuoteContactDescription2: 'CustomQuoteContactDescription2',
        CustomQuoteContactDescription3: 'CustomQuoteContactDescription3',
        SendButton: 'SendButton',
        CustomQuoteContactNumber: 'CustomQuoteContactNumber',
        QuoteCompleteButton: 'QuoteCompleteButton',
        QuoteCompleteMessage: 'QuoteCompleteMessage',
        CompilingScreenTitle: 'CompilingScreenTitle',
        CompilingScreenDescription: 'CompilingScreenDescription',
        BackButton: 'BackButton',
        ALLPartsButton: 'ALLPartsButton',
        IssueOnlyButton: 'IssueOnlyButton',
        BOMIsCleanMessage: 'BOMIsCleanMessage',
        DownloadBOM: 'DownloadBOM',
        BOMSummarySectionTitle: 'BOMSummarySectionTitle',
        DownloadQuote: 'DownloadQuote',
        QuantityColumnHeader: 'QuantityColumnHeader',
        TurnTimeColumnHeader: 'TurnTimeColumnHeader',
        AssemblyCostColumnHeader: 'AssemblyCostColumnHeader',
        AdditionalServicesColumnHeader: 'AdditionalServicesColumnHeader',
        PCBColumnHeader: 'PCBColumnHeader',
        TotalPriceColumnHeader: 'TotalPriceColumnHeader',
        BillingAndShippingTitle: 'BillingAndShippingTitle',
        ShippingAddressSubTitle: 'ShippingAddressSubTitle',
        BillingAddressSubTitle: 'BillingAddressSubTitle',
        ShippingMethodSubTitle: 'ShippingMethodSubTitle',
        OrderSummarySubTitle: 'OrderSummarySubTitle',
        SeparateBillingAddressSubTitle: 'SeparateBillingAddressSubTitle',
        QuantityLabel: 'QuantityLabel',
        LeadTimeLabel: 'LeadTimeLabel',
        RegularPriceSubTotalLabel: 'RegularPriceSubTotalLabel',
        ShippingLabel: 'ShippingLabel',
        TotalLabel: 'TotalLabel',
        HideOrderDetailsButton: 'HideOrderDetailsButton',
        ViewOrderDetailsButton: 'ViewOrderDetailsButton',
        PCBDetailsLabel: 'PCBDetailsLabel',
        NetPayableTitle: 'NetPayableTitle',
        StartNewQuoteButton: 'StartNewQuoteButton',
        GetQuotePageTitle: 'GetQuotePageTitle',
        LineColumnHeader: 'LineColumnHeader',
        ManufacturerColumnHeader: 'ManufacturerColumnHeader',
        MPNColumnHeader: 'MPNColumnHeader',
        DescriptionColumnHeader: 'DescriptionColumnHeader',
        ReferenceDesignatorColumnHeader: 'ReferenceDesignatorColumnHeader',
        TotalQuantityColumnHeader: 'TotalQuantityColumnHeader',
        MethodOfSupplyColumnHeader: 'MethodOfSupplyColumnHeader',
        IssueAlertColumnHeader: 'IssueAlertColumnHeader',
        FileImportMessage1: 'FileImportMessage1',
        FileImportMessage2: 'FileImportMessage2',
        MaxFileUploadMessage: 'MaxFileUploadMessage',
        ChooseFileButton: 'ChooseFileButton',
        ScrubMyBomTitle: 'ScrubMyBomTitle',
        ScrubMyBomSubTitle: 'ScrubMyBomSubTitle',
        ContinueButton: 'ContinueButton',
        ViewButton: 'ViewButton',
        ReorderButton: 'ReorderButton',
        RepriceAlternatesButton: 'RepriceAlternatesButton',
        SuggestAlternatesButton: "SuggestAlternatesButton",
        AddAlternatesManuallyButton: "AddAlternatesManuallyButton",
        SuggestAlternateModalTitle: 'SuggestAlternateModalTitle',
        SaveSelectedAlternatesButton: 'SaveSelectedAlternatesButton',
        AddAlternateTitle: 'AddAlternateTitle',
        SaveAlternatesButton: 'SaveAlternatesButton',
        CreateAQuoteButton: 'CreateAQuoteButton',
        BOMAnalysisButton: 'BOMAnalysisButton',
        CreateAQuoteDescription: 'CreateAQuoteDescription',
        BOMAnalysisDescription: 'BOMAnalysisDescription',
        QuoteSelectionPageTitle: 'QuoteSelectionPageTitle',
        QuoteSelectionPageSubTitle: 'QuoteSelectionPageSubTitle',
        QuickPriceButton: 'QuickPriceButton',
        QuickPriceDescription: 'QuickPriceDescription',
        DownloadAllPricing: 'DownloadAllPricing',
        FirstNameLabel: 'FirstNameLabel',
        LastNameLabel: 'LastNameLabel',
        EmailLabel: 'EmailLabel',
        PhoneLabel: 'PhoneLabel',
        CompanyLabel: 'CompanyLabel',
        QuoteTypeLabel: 'QuoteTypeLabel',
        AssemblyNameLabel: 'AssemblyNameLabel',
        AssemblyNumberLabel: 'AssemblyNumberLabel',
        RevLabel: 'RevLabel',
        TotalTurnTimeLabel: 'TotalTurnTimeLabel',
        NoOfSMTParts: 'NoOfSMTParts',
        NoOfTHParts: 'NoOfTHParts',
        TotalNoOfLineItem: 'TotalNoOfLineItem',
        NameLabel: 'NameLabel',
        StreetAddressLine1Label: 'StreetAddressLine1Label',
        StreetAddressLine2Label: 'StreetAddressLine2Label',
        PostalCodeLabel: 'PostalCodeLabel',
        CityLabel: 'CityLabel',
        StateLabel: 'StateLabel',
        ChooseShippingMethod: 'ChooseShippingMethod',
        UnpricedLineItem: 'UnpricedLineItem',
        CrossMatch: 'CrossMatch',
        OutofStock: 'OutofStock',
        LongLeadTime: 'LongLeadTime',
        InStockButLongLeadTime: 'InStockButLongLeadTime',
        Select: 'Select',
        AssemblyNameColumnHeader: 'AssemblyNameColumnHeader',
        AssemblyNumberRevColumnHeader: 'AssemblyNumberRevColumnHeader',
        RequestedQTYTurnTimeColumnHeader: 'RequestedQTYTurnTimeColumnHeader',
        OrderedQTYTurnTimeColumnHeader: 'OrderedQTYTurnTimeColumnHeader',
        OrderPriceColumnHeader: 'OrderPriceColumnHeader',
        QuoteDateColumnHeader: 'QuoteDateColumnHeader',
        StatusColumnHeader: 'StatusColumnHeader',
        ActionsColumnHeader: 'ActionsColumnHeader',
        UploadedFilesTitle: 'UploadedFilesTitle',
        QuoteHistoryHeader: 'QuoteHistoryHeader',
        QuoteHistoryLinkText: 'QuoteHistoryLinkText',
        NoOfIssues: 'NoOfIssues',
        LoginLinkText: 'LoginLinkText',
        LogoutLinkText: 'LogoutLinkText',
        RejectedFilesTitle: 'RejectedFilesTitle',
        FilesTypeLabel: 'FilesTypeLabel',
        NoteLabel: 'NoteLabel',

        YesButton: 'YesButton',
        NoButton: 'NoButton',
        CustomQuoteWarning: 'CustomQuoteWarning',
        DeleteFileAlert: 'DeleteFileAlert',
        DeleteQuantityAlert: 'DeleteQuantityAlert',
        DeleteTurnTimeAlert: 'DeleteTurnTimeAlert',
        MinimumOneQuantityRequired: 'MinimumOneQuantityRequired',
        MinimumOneTurnTimeRequired: 'MinimumOneTurnTimeRequired',
        StartNewQuoteConfirmation: 'StartNewQuoteConfirmation',
        AssemblyDetailNotConfigured: 'AssemblyDetailNotConfigured',
        NoPCBLineAdded: 'NoPCBLineAdded',
        NavigationBlockMessage: 'NavigationBlockMessage',
        CustomQuoteWarningForChecklist: 'CustomQuoteWarningForChecklist',
        ReorderRFQ: 'ReorderRFQ',
        RequiredMessage: 'RequiredMessage',
        InvalidValueMessage: 'InvalidValueMessage',
        MaxLengthMessage: 'MaxLengthMessage',
        MinValueMessage: 'MinValueMessage',
        RequiredMethodOfSupply: 'RequiredMethodOfSupply',
        InvalidAmount: 'InvalidAmount',
        SelectAlternate: 'SelectAlternate',
        DuplicatePartNumber: 'DuplicatePartNumber',
        RequireAlternate: 'RequireAlternate',
        MinLengthMessage: 'MinLengthMessage',
        BOMHealthMonitorHeader: 'BOMHealthMonitorHeader',
        BOMHealthMonitorHistoryHeader: 'BOMHealthMonitorHistoryHeader',
        CreateSchedule: 'CreateSchedule',
        DeleteButton: 'DeleteButton',
        EditButton: 'EditButton'
    },

    jargonValues: [],
    jargonObject: {}
}