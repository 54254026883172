import React from 'react';
import { BiCart, BiPlusCircle, BiX, BiXCircle, BiSend  } from "react-icons/bi";
import './InputButton.css';
import { Button } from 'react-bootstrap';

export const RemoveButton = (props) => {
    return (
        <BiXCircle className={`btn-remove ${props.className}`} onClick={props.handleClick} />
    )
};

export const AddButton = (props) => {
    return (
        <BiPlusCircle className={`btn-add ${props.className}`} onClick={props.handleClick} />
    )
};

export const PrimaryButton = (props) => {
    return (
        <Button className={`btn-generic-primary ${props.className}`} onClick={props.handleClick} disabled={props.disabled}>{props.name}</Button>
    )
};

export const SecondaryButton = (props) => {
    return (
        <Button className={`btn-secondary ${props.className}`} onClick={props.handleClick} disabled={props.disabled}>{props.name}</Button>
    )
};

export const SmallGreyButton = (props) => {
    let isActive = props.isActive === true ? "isActive" : " ";
    return (
        <Button className={"btn-filter-toggle " + isActive} onClick={props.handleClick}>{props.name}</Button>
    )
};

export const LinkButton = (props) => {
    return (
        <Button variant="link" onClick={props.handleClick}>{props.name}</Button>
    )
};

export const ShoppingCartButton = (props) => {
    return (
        <BiCart className={`float-right cm-cart-icon ${props.isDisabled ? 'disable-input' : ''}`} key={props.rowKey} onClick={props.handleClick} />
    );
};

export const ReportButton = (props) => {
    return (
        <Button className={`btn-report-secondary ${props.className}`} onClick={props.handleClick}>{props.name}</Button>
    );
}

export const CloseButton = (props) => {
    return (
        <BiX className={`btn-close ${props.className}`} onClick={props.handleClick} />
    )
};

export const SendButton = (props) => {
    return (
        <BiSend  className={`cm-send-icon ${props.className} ${props.isDisabled ? 'disable-input' : ''}`} onClick={props.handleClick} />
    );
};