import React, { useEffect, useState } from "react";
import Header from '../components/Header/Header';
import './RfqProcessLayout.css'
import { Container, Row, Col } from "react-bootstrap";
import RFQSteps from "../components/Navigation/RFQSteps";
import { connect } from "react-redux";
import { PrimaryButton } from "../components/ControlComponents/InputButton/InputButton";
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import { resetCompletedSteps } from '../redux/actions/CompletedStepActions';
import { clearJobType } from '../redux/actions/ClearJobType';
import NavigationBlocker from "../components/CommonComponents/NavigationBlocker";
import { JARGON_CONSTANT } from "../constants/JargonConstant";
import { CommonService } from "../services/CommonService";
import { clearStore } from "../redux/actions";
import { DATA_CONSTANT } from "../constants/DataConstant";

const RfqProcessLayout = ({ selectedJobType, completedSteps, isCustomQuoteRequires, children, reset }) => {
    const history = useHistory();

    const [navigationBlocked, setNavigationBlock] = useState(false);

    const [isButtonClicked, setIsButtonClicked] = useState(false);

    useEffect(() => {
        if (isButtonClicked) {
            setNavigationBlock(false);
        }
    }, [isButtonClicked]);

    const createQuoteConfirmation = () => {
        setIsButtonClicked(true);
        setNavigationBlock(true);
        Swal.fire({
            text: JARGON_CONSTANT.jargonObject.StartNewQuoteConfirmation,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: JARGON_CONSTANT.jargonObject.YesButton,
            cancelButtonText: JARGON_CONSTANT.jargonObject.NoButton,
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                if (selectedJobType) {
                    selectedJobType.isAssemblyRequired = false;
                    selectedJobType.isBomRequired = false;
                    selectedJobType.isComponentsRequired = false;
                    selectedJobType.isPcbRequired = false;
                    selectedJobType.isValid = false;
                    selectedJobType.jobType = [];
                    selectedJobType.cqJobType = "";
                    reset(selectedJobType);
                }
                history.push(CommonService.getRoutePath(""));
            }
        })
    }
    return (
        <div className="body-wrapper">
            <Header />
            <div id="main-wrapper">
                <Container fluid>
                    <Row>
                        <Col md={2} className="mb-4">
                            <NavigationBlocker navigationBlocked={navigationBlocked} />
                            {(DATA_CONSTANT.IsAnyQuoteFeatureEnabled && !DATA_CONSTANT.IsPCBNetClient)?
                                <PrimaryButton className="start-new-quote-btn mb-3" name={CommonService.getJargonValue(JARGON_CONSTANT.keys.StartNewQuoteButton)} handleClick={createQuoteConfirmation} ></PrimaryButton>
                                : <div className= "mb-5 pb-3"></div>
                            }
                            {!selectedJobType?.isBomHealthQuote &&
                                <>
                                    <RFQSteps
                                        completedSteps={completedSteps}
                                        isCustomQuoteRequires={isCustomQuoteRequires}
                                        selectedJobType={selectedJobType}
                                    />
                                </>
                            }
                        </Col>
                        <Col md={10}>
                            {children}
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
};

const mapStateToProps = state => ({
    completedSteps: state.CompletedSteps ? state.CompletedSteps : [],
    selectedJobType: state.JobTypes?.selectedJobType,
    isCustomQuoteRequires: state.RFQDetails?.isCustomQuoteRequires
});

const mapDispatchToProps = dispatch => ({
    reset: (selectedJobType) => {
        dispatch(clearStore());
        dispatch(resetCompletedSteps());
        dispatch(clearJobType(selectedJobType));
    }
});


export default connect(mapStateToProps, mapDispatchToProps)(RfqProcessLayout);