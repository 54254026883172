import React, { useEffect } from 'react'
import { trackPromise } from 'react-promise-tracker';
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { ConfigRoutesConstants } from '../../constants/ConfigRoutesConstants';
import { DATA_CONSTANT } from '../../constants/DataConstant';
import { OIDCService } from '../../constants/UserManager';
import { AccountService } from '../../services/AccountService';
import { CommonService } from '../../services/CommonService';
import './ConfigurationSideMenu.css';

const ConfigurationSideMenu = () => {

    const menuItem = ConfigRoutesConstants.configurationRouteList;
    const location = useLocation();
    const { pathname } = location;
    const activeLocation = pathname.split("/configurations/");
    const history = useHistory();

    useEffect(() => {

        let user = OIDCService.getUser();
        let isSuperAdmin = user?.profile?.IsSuperAdmin;
        if (isSuperAdmin?.toLowerCase() == "false") {
            let userId = user?.profile?.sub;
            trackPromise(validateUserAccess(userId))
        }

    }, []);

    const validateUserAccess = async (userId) => {
        let isUserPortCQAdmin = await AccountService.isUserPortCQAdmin(userId);
        if (isUserPortCQAdmin?.toLowerCase() != "true") {
            history.push(CommonService.getRoutePath(DATA_CONSTANT.routes.error), 401);
        }
    }

    return (
        <div className="cm-sidenav d-flex flex-column shadow border-top">
            <div className='shadow-sm'>
                <div className="d-flex align-item-center flex-wrp border-bottom px-3 px-md-4 py-1">
                    <h3 className="cm-configuration-title-mr">Configurations</h3>
                </div>
            </div>
            <div className="cm-overflow-x-hidden flex-grow overflow-auto py-2 px-3 px-md-4">
                <nav className="navbar p-0 navbar-light">
                    <ul className="navbar-nav cm-sidenav-list mb-2 mb-lg-0">
                        {menuItem?.length > 0 &&
                            menuItem.map((route) => {
                                if (route.linkDisplay) {
                                    return (<li key={route.id} className={activeLocation[1] === route.routeName ? "nav-item p-2 active" : "nav-item p-2"}>
                                        <NavLink to={route.routeUrl} key={`Nav${route.id}`}>
                                            {route.title}
                                        </NavLink>
                                    </li>
                                    )
                                }
                            })
                        }
                    </ul>
                </nav>
            </div>
        </div>
    )
}
export default ConfigurationSideMenu;