import { DocumentService } from "./DocumentService";
import { trackPromise } from "react-promise-tracker";

export const UploadDocumentService = {
    uploadDocuments: (assemblyId, rfqAcceptedFiles) => new Promise((resolve, reject) => {
        DocumentService.generatePreSignedUrl(rfqAcceptedFiles.length).then((preSignedUrls) => {
            for (let i = 0; i < preSignedUrls.presignedURLResponses.length; i++) {
                preSignedUrls.presignedURLResponses[i].file = rfqAcceptedFiles[i];
                preSignedUrls.presignedURLResponses[i].documentName = rfqAcceptedFiles[i].name;
                preSignedUrls.presignedURLResponses[i].contentLength = rfqAcceptedFiles[i].size;
                preSignedUrls.presignedURLResponses[i].filetype = rfqAcceptedFiles[i].filetype;
            }
            DocumentService.uploadFilesToS3(preSignedUrls.presignedURLResponses).then((uploadDocResponse) => {
                const errors = uploadDocResponse.filter((response) => !response.ok);
                if (errors.length === 0) {
                    let requestDoc = {
                        assemblyId: assemblyId,
                        documents: preSignedUrls.presignedURLResponses
                    }
                    trackPromise(DocumentService.insertDocuments(requestDoc).then((data) => {
                        resolve(errors);
                    }));
                }
                else {
                    reject(errors);
                }
            });
        });
    })
}