import React, { useState, useEffect } from 'react';
import { Nav, NavItem } from 'react-bootstrap';
import { FaRegCheckCircle, FaRegCircle } from 'react-icons/fa';
import './RFQSteps.css'
import { Steps } from 'antd';
import { DATA_CONSTANT } from '../../constants/DataConstant';
import { RFQProcessStatusService } from "../../services/RFQProcessStatusService";
import { useHistory, useLocation } from "react-router-dom";
import { difference, pluck } from "underscore";
import { CommonService } from "../../services/CommonService";

const RFQSteps = ({ completedSteps, isCustomQuoteRequires, selectedJobType }) => {

    const { Step } = Steps;
    const [rfqSteps, setRFQSteps] = useState([]);
    const [disableSteps, setDisableSteps] = useState([]);

    let activeClass = "cm-active-step";
    let disableClass = "cm-link-disable"
    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        if (selectedJobType?.cqJobType) {
            getRFQSteps(selectedJobType?.cqJobType);
        }
    }, [isCustomQuoteRequires]);

    const getRFQSteps = async (cqJobType) => {
        await RFQProcessStatusService.getRFQSteps(cqJobType).then((data) => {
            setRFQSteps(data);
            // Assign api response to data constant so that can use in all pages
            DATA_CONSTANT.rfqSteps = data;
            // Disable steps in case of custom quote
            if (isCustomQuoteRequires && completedSteps.length > 0) {
                let stepNames = pluck(data, "stepName");
                let disableSteps = difference(stepNames, completedSteps);
                setDisableSteps(disableSteps);
            }
        });
    }

    function CurrentStepForCheckOut() {
        let currentStep = -1;
        if (completedSteps.indexOf(DATA_CONSTANT.stepNames.CheckoutBillingShipping) > -1) {
            currentStep = 0;
        }
        if (completedSteps.indexOf(DATA_CONSTANT.stepNames.CheckoutPayment) > -1) {
            currentStep = 1;
        } if (completedSteps.indexOf(DATA_CONSTANT.stepNames.CheckoutPayment) > -1) {
            currentStep = 2;
        }
        return currentStep;
    }

    const redirectToStep = (stepName, route) => {
        if (!isCustomQuoteRequires && (completedSteps.indexOf(stepName) > -1) && completedSteps.indexOf(DATA_CONSTANT.stepNames.Completed) <= -1) {
            history.push(CommonService.getRoutePath(route));
        }
    }

    return (
        rfqSteps && rfqSteps.length > 0 &&
        <Nav defaultActiveKey="/bom" className="rfq-steps flex-column">
            {rfqSteps.map((link) => {
                return (
                    <NavItem key={link.id}>
                        <Nav.Link eventKey={link.stepName}
                            href='#'
                            onClick={() => redirectToStep(link.stepName, link.routeName)}
                            disabled={link.stepName === (DATA_CONSTANT.stepNames.BOM || DATA_CONSTANT.stepNames.Completed) ? true : false}
                            className={(location.pathname === ('/' + (link.stepName).toLowerCase()) ? activeClass : (disableSteps.indexOf(link.stepName) > -1 ? disableClass : ""))}>
                            {(completedSteps.indexOf(link.stepName) > -1) ? <FaRegCheckCircle className="icon-color" /> : <FaRegCircle />} {link.displayName}
                        </Nav.Link>

                        {link.childSteps.length > 0 &&
                            <div className="cm-checkout-stepper">
                                <Steps progressDot current={CurrentStepForCheckOut()} direction="vertical">
                                    {link.childSteps.map((child) => {
                                        return (
                                            <Step key={child.id}
                                                title={child.displayName} />
                                        )
                                    })}
                                </Steps>
                            </div>
                        }
                    </NavItem>
                );
            })}
        </Nav>
    )
};
export default RFQSteps;