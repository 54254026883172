import { CommonHttpService } from './CommonHttpService';
import { URL_CONSTANT } from '../constants/UrlConstant';

export const RequestFormService = {

    getDefaultConfigurations: (jobType) => {
        const url = `${URL_CONSTANT.RequestForm.GetDefaultConfigurations}?jobType=${jobType}`;
        return CommonHttpService.httpGet(url);
    },

    CreateRFQ: (data) => {
        return CommonHttpService.httpPost(URL_CONSTANT.RequestForm.CreateRFQ, data);
    },

    UpdateRFQ: (data) => {
        return CommonHttpService.httpPut(URL_CONSTANT.RequestForm.UpdateRFQ, data);
    },
    CloneRFQ: (data) => {
        return CommonHttpService.httpPost(URL_CONSTANT.RequestForm.CloneRFQ, data);
    },

    GetAllQuoteType: () => {
        return CommonHttpService.httpGet(URL_CONSTANT.RequestForm.GetAllQuoteType);
    },

    GetRFQDetails: (rfqId, assemblyId) => {
        const url = `${URL_CONSTANT.RequestForm.GetRFQDetails}?rfqId=${rfqId}&&assemblyId=${assemblyId}`;
        return CommonHttpService.httpGet(url);
    },
    GetRFQData: (assemblyId) => {
        const url = `${URL_CONSTANT.RequestForm.GetRFQDetails}?assemblyId=${assemblyId}`;
        return CommonHttpService.httpGet(url);
    },
    GetRFQDocuments: (userId, assemblyId) => {
        const url = `${URL_CONSTANT.RequestForm.GetRFQDocuments}?userId=${userId}&&assemblyId=${assemblyId}`;
        return CommonHttpService.httpGet(url);
    },

    DeleteQuantity: (data) => {
        return CommonHttpService.httpPost(URL_CONSTANT.RequestForm.DeleteQuantity, data);
    },

    DeleteTurnTime: (data) => {
        return CommonHttpService.httpPost(URL_CONSTANT.RequestForm.DeleteTurnTime, data);
    },

    DeleteRFQDocument: (data) => {
        return CommonHttpService.httpPost(URL_CONSTANT.RequestForm.DeleteRFQDocument, data);
    },

    UpdateAssemblyFieldValue: (data) => {
        return CommonHttpService.httpPost(URL_CONSTANT.RequestForm.UpdateAssemblyFieldValue, data);
    },

    UpdateAdjustedTurnTimeApprovalStatus: (data) => {
        return CommonHttpService.httpPost(URL_CONSTANT.RequestForm.UpdateAdjustedTurnTimeApprovalStatus, data);
    },

    GetRFQList: (data) => {
        return CommonHttpService.httpPost(URL_CONSTANT.RequestForm.GetRFQList, data);
    },

    GetRFQInfoForContinue: (data) => {
        return CommonHttpService.httpPost(URL_CONSTANT.RequestForm.GetRFQInfoForContinue, data);
    },

    CreateBomHealthAssembly: (data) => {
        return CommonHttpService.httpPost(URL_CONSTANT.RequestForm.CreateBomHealthAssembly, data);
    },

    GetCustomerContactByEmail: (email) => {
        const url = `${URL_CONSTANT.RequestForm.GetCustomerContactByEmail}?email=${email}`;
        return CommonHttpService.httpGet(url);
    },
}