import { RESET_COMPLETED_STEPS, SET_ALL_COMPLETED_STEPS, SET_COMPLETED_STEPS } from '../../constants/ActionConstants';
import { first } from 'underscore';

const InitialState = [];
export const CompletedSteps = (state = InitialState, action) => {
    const { type, data } = action;

    switch (type) {
        case SET_COMPLETED_STEPS:
            let stateData = [...state];
            let stepIndex = stateData.indexOf(data.stepName);
            if (stepIndex < 0) {
                stateData.push(data.stepName);
            } else {
                stateData = first(stateData, stepIndex + 1);
            }
            return stateData;
        case RESET_COMPLETED_STEPS:
            return InitialState;
        case SET_ALL_COMPLETED_STEPS:
            return data;
        default:
            return state;
    }
}