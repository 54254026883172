import { CommonHttpService } from './CommonHttpService';
import { URL_CONSTANT } from '../constants/UrlConstant';
import { SessionStorageService } from './SessionStorageService';

export const RFQProcessStatusService = {

    getRFQSteps: (jobTypeName) => {
        let url = `${URL_CONSTANT.RFQProcessStatus.GetRFQSteps}`;
        let params = { jobType: jobTypeName };
        return CommonHttpService.httpGet(url, params);
    },
    saveCompletedStep: (assemblyId, stepName, isCompleted) => {
        let storeJson = JSON.parse(SessionStorageService.getSessionValue('persist:root'));
        let jobTypeName = JSON.parse(storeJson.JobTypes).selectedJobType.cqJobType;

        let data = {
            assemblyId: assemblyId,
            stepName: Array.isArray(stepName) ? stepName : [stepName],
            isCompleted: isCompleted,
            jobTypeName: jobTypeName
        };
        return CommonHttpService.httpPost(URL_CONSTANT.RFQProcessStatus.
            SaveCompletedStep, data);
    },
    getRFQStepsForConfiguration: () => {
        return CommonHttpService.httpGet(URL_CONSTANT.RFQProcessStatus.GetRFQStepsForConfiguration);
    },

    updateRFQSteps: (data) => {
        return CommonHttpService.httpPost(URL_CONSTANT.RFQProcessStatus.UpdateRFQSteps, data);
    },
    getRFQProcessStatus: (assemblyId, jobTypeName) => {
        let url = `${URL_CONSTANT.RFQProcessStatus.GetRFQProcessStatus}`;
        let params = { assemblyId: assemblyId, jobType: jobTypeName };
        return CommonHttpService.httpGet(url, params);
    }
}