import React, { useEffect, useState } from "react";
import { LoadingSpiner } from "./components/CommonComponents/LoadingSpiner";
import { DATA_CONSTANT } from "./constants/DataConstant";
import { URL_CONSTANT } from "./constants/UrlConstant";
import "./global.style.css";
import Extension from './prototype/Extensions';
import Routes from "./Routes";
import { CommonHttpService } from "./services/CommonHttpService";
import { connect } from "react-redux";
import { setClientName } from './redux/actions/index';
import { OIDCService } from "./constants/UserManager";
import { JargonService } from './services/JargonService';
import { JARGON_CONSTANT } from './constants/JargonConstant';
import LogRocket from 'logrocket';
import setupLogRocketReact from "logrocket-react";
import usePageViews from "../src/CustomHooks/GoogleAnalytics/UsePageViews";

const App = ({ onClientLoad }) => {
    Extension();

    const [isUserLoaded, setIsUserLoaded] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);

    usePageViews();

    useEffect(() => {

        fetchData();

    }, []);

    const fetchData = async () => {
        let user = await checkUserLoggedIn();
        getConfiguration(user);
        setImportWidgetUrl();
        getJargonList();
        getFeatures();
    }

    const checkUserLoggedIn = async () => {
        let user = null;
        await OIDCService.loadUser().then(function (userData) {
            user = userData;
            setIsUserLoaded(true);
        }).catch(function () { setIsUserLoaded(true); });
        return user;
    };

    const getFeatures = async () => {
        let configKeys = [DATA_CONSTANT.ConfigurationKey.IsBomAnalysisEnabled, DATA_CONSTANT.ConfigurationKey.IsGetAQuoteEnabled,
        DATA_CONSTANT.ConfigurationKey.IsQuickPriceEnabled, DATA_CONSTANT.ConfigurationKey.IsBOMHealthEnabled];
        await CommonHttpService.httpPost(URL_CONSTANT.Configuration.GetConfigurations, configKeys).then((data) => {
            if (data && (data.length > 0)) {
                DATA_CONSTANT.IsAnyQuoteFeatureEnabled = data.some(x => x.value.toLowerCase() == 'true');
                DATA_CONSTANT.QuoteFeatures = data;
            }
        });
    }

    const getJargonList = async () => {
        await JargonService.GetJargonList().then((data) => {
            if (data) {
                JARGON_CONSTANT.jargonValues = data.jargonList;
                let jargonObject = {};
                data.jargonList.forEach((elem, i) => {
                    jargonObject[elem.label] = elem.displayValue;
                });
                JARGON_CONSTANT.jargonObject = jargonObject;
                setIsLoaded(true);
            }
        })
    }

    const getConfiguration = (user) => {
        let intercomAppId = DATA_CONSTANT.ConfigurationKey.IntercomAppId;
        let tagId = DATA_CONSTANT.ConfigurationKey.GoogleAnalyticsTagId;
        let clientName = DATA_CONSTANT.ConfigurationKey.ClientName;
        let themeName = DATA_CONSTANT.ConfigurationKey.ThemeName;
        let logRocketAppIdKey = DATA_CONSTANT.ConfigurationKey.LogRocketAppId;
        let isLogRocketEnabledKey = DATA_CONSTANT.ConfigurationKey.IsLogRocketEnabled;
        let isBOMHealthEnabledKey = DATA_CONSTANT.ConfigurationKey.IsBOMHealthEnabled;

        const configKeys = [DATA_CONSTANT.ConfigurationKey.HubSpotAppId, intercomAppId, tagId, clientName, themeName, isLogRocketEnabledKey, logRocketAppIdKey, isBOMHealthEnabledKey];

        CommonHttpService.httpPost(URL_CONSTANT.Configuration.GetConfigurations, configKeys)
            .then((data) => {
                if (data && (data.length > 0)) {

                    data.map((val) => {

                        if (!val.value) {
                            return false;
                        }

                        if (val.key === themeName) {
                            let clientTheme = val.value;
                            let doc = document;
                            let basePath = process.env.PUBLIC_URL
                            let clientCssUrl = `${basePath}/themes/${clientTheme}.css`;
                            let clientFaviconUrl = `${basePath}/favicon/${clientTheme}.png`;
                            let themeLink = doc.createElement("link");
                            themeLink.href = clientCssUrl;
                            themeLink.rel = 'stylesheet';
                            themeLink.type = 'text/css';
                            doc.getElementsByTagName("head")[0].appendChild(themeLink);
                            doc.documentElement.setAttribute('data-theme', clientTheme);
                            doc.querySelector(`link[rel="shortcut icon"]`).href = clientFaviconUrl;
                        } else if (val.key === clientName) {
                            onClientLoad(val.value);
                        }
                        else if (val.key === intercomAppId) {
                            window.intercomSettings = {
                                app_id: val.value
                            };
                            const d = document;
                            const s = d.createElement("script");
                            s.innerHTML = `(function()
                    {
                      var w=window;
                      var ic=w.Intercom;
                      if(typeof ic==="function"){
                        ic('reattach_activator');
                        ic('update',w.intercomSettings);
                      }else{
                        var d=document;
                        var i=function(){
                          i.c(arguments);
                        };
                        i.q=[];
                        i.c=function(args){
                          i.q.push(args);
                        };
                        w.Intercom=i;
                        var l=function(){
                          var s=d.createElement('script');
                          s.type='text/javascript';
                          s.async=true;
                          s.src='https://widget.intercom.io/widget/${val.value}';
                          var x=d.getElementsByTagName('script')[0];
                          x.parentNode.insertBefore(s,x);
                        };
                          if(w.attachEvent){w.attachEvent('onload',l);
                        }else{
                          w.addEventListener('load',l,false);}
                        }
                        l();
                      })();`;
                            d.getElementsByTagName("head")[0].appendChild(s);
                        } else if (val.key === tagId) {
                            const doc = document;
                            const managerscript = doc.createElement("script");
                            managerscript.src = "https://www.googletagmanager.com/gtag/js?id=" + val.value;
                            managerscript.async = true;
                            doc.getElementsByTagName("head")[0].appendChild(managerscript);
                            const gtag = doc.createElement("script");
                            gtag.innerHTML = `window.dataLayer = window.dataLayer || [];
                                function gtag(){dataLayer.push(arguments);};
                                gtag('js', new Date());
                                gtag('config', '${val.value}');`;
                            gtag.type = 'text/javascript';
                            gtag.async = true;
                            doc.getElementsByTagName("head")[0].appendChild(gtag);

                            DATA_CONSTANT.IsGoogleAnalyticsEnabled = true;

                        }
                        else if (val.key === isLogRocketEnabledKey && val.value === 'true') {

                            var logRocketAppId = data.find(x => x.key == logRocketAppIdKey)?.value
                            if (logRocketAppId) {
                                LogRocket.init(logRocketAppId);
                                if (user && user.profile) {
                                    LogRocket.identify(user.profile.sub, {
                                        name: `${user.profile.FirstName} ${user.profile.LastName}`,
                                        email: user.profile.Email
                                    });
                                }
                                setupLogRocketReact(LogRocket);

                            }
                        }
                        else if (val.key === isBOMHealthEnabledKey && val.value === 'true') {

                            const bomHealthWidgetUrl = window.$config.bomHealthWidgetUrl;
                            const link = document.createElement('link');
                            link.rel = "stylesheet";
                            link.type = "text/css";
                            link.href = `${bomHealthWidgetUrl}/widget.css`;
                            document.body.appendChild(link);

                            const script = document.createElement('script');
                            script.src = `${bomHealthWidgetUrl}/widget.js`;
                            script.id = 'BomHealthWidgetScript';
                            document.body.appendChild(script);
                        }
                        else if (val.key === DATA_CONSTANT.ConfigurationKey.HubSpotAppId) {

                            var hsChatScript = document.createElement('script');
                            hsChatScript.type = 'text/javascript';
                            hsChatScript.id = "hs-script-loader";
                            hsChatScript.async = true;
                            hsChatScript.defer = true;
                            hsChatScript.src = '//js.hs-scripts.com/' + val.value + '.js';
                            document.body.appendChild(hsChatScript);

                            setTimeout(() => {
                                bootHubSpotChat(user);
                            }, 2000)
                        }
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const setImportWidgetUrl = () => {
        const importWidgetUrl = window.$config.smartImportWidgetUrl ? window.$config.smartImportWidgetUrl : window.$config.importWidgetUrl;
        document.getElementById('scriptImportWidgetUrl').src = `${importWidgetUrl}/widget.js`;
    }

    const bootHubSpotChat = (user) => {

        if (window._hsq) {

            if (user?.profile) {
                window._hsq.push([
                    "identify",
                    {
                        email: user.profile.Email,
                        firstName: user.profile.FirstName,
                        lastName: user.profile.LastName,
                    },
                ]);
            }

            window._hsq.push(["trackPageView"]);
        }

        if (window.HubSpotConversations) {
            onHubSpotConversationsAPIReady();
        } else {
            window.hsConversationsOnReady = [onHubSpotConversationsAPIReady];
        }
    }

    const onHubSpotConversationsAPIReady = () => {
        const status = window.HubSpotConversations.widget.status();
        if (status.loaded) {
            window.HubSpotConversations.widget.refresh();
        } else {
            window.HubSpotConversations.widget.load();
        }
    }

    return (
        <>
            {
                isUserLoaded && isLoaded ? (
                    <Routes />
                ) : null
            }
            <LoadingSpiner />
        </>
    );
};

const mapDispatchToProps = (dispatch) => ({
    onClientLoad: (clientName) => dispatch(setClientName(clientName))
})

export default connect(null, mapDispatchToProps)(App);
