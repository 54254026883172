import { createUserManager } from 'redux-oidc';
import { loadUser } from "redux-oidc";
import { SessionStorageService } from '../services/SessionStorageService';
import { history } from '../History';
import { DATA_CONSTANT } from '../constants/DataConstant';
import { configureStore } from '../redux/store';
import { CommonService } from "../services/CommonService";

let OIDCService = {};

const initializeUserManager = (config) => {
    let urlClientCode = DATA_CONSTANT.IsCustomDomainClient ? "" : "/" + DATA_CONSTANT.ClientCode;
    const userConfig = {
        authority: config?.authenticationUrl,
        client_id: config?.portCQClientId,
        redirect_uri: `${config?.portCQRootUrl}${urlClientCode}/callback.html`,
        response_type: 'code',
        response_mode: 'query',
        scope: `${config?.apiScopesToRequest}`,
        silent_redirect_uri: `${config?.portCQRootUrl}${urlClientCode}/silent_renew.html`,
        automaticSilentRenew: true,
        revokeAccessTokenOnSignout: true,
        post_logout_redirect_uri: `${config?.portCQRootUrl}${urlClientCode}`,
        accessTokenExpiringNotificationTime: `${config?.accessTokenExpiringNotification}` // seconds
    };
    let UserManager = createUserManager(userConfig);

    UserManager.events.addUserSignedOut(() => {
        OIDCService.logout();
    });
    UserManager.events.addUserLoaded(() => { });

    UserManager.events.addSilentRenewError(function (e) {
        if (e.message == 'Unauthorized') {
            history.push(CommonService.getRoutePath(DATA_CONSTANT.routes.error), 401);
        }
    });


    let store = configureStore();
    OIDCService = {
        login: () => {
            if (!SessionStorageService.getPostLoginUrl()) {
                SessionStorageService.setPostLoginUrl(window.location.pathname);
            }
            const user = store.getState().oidc.user;          
            if (!user || user.expired) {
                let userState = {
                    'state': { uri: JSON.stringify(window.location.href) }
                };
                UserManager.signinRedirect(userState);
                return false;
            }
            return true;
        },
        logout: () => {
            const user = store.getState().oidc.user;
            UserManager.removeUser();
            UserManager.signoutRedirect({ 'id_token_hint': user.id_token }).catch((error) => { this.handleError(error); history.push(CommonService.getRoutePath(DATA_CONSTANT.routes.error), 500); });
            SessionStorageService.removeSessionValue("LoggedInUserEmail");
        },
        loadUser: async () => new Promise((resolve, reject) => {
            loadUser(store, UserManager).then(function (user) {
                resolve(user);
            }).catch(function (err) { resolve(err); history.push(CommonService.getRoutePath(DATA_CONSTANT.routes.error), 500); });
        }),
        getUser: () => {
            const user = store.getState().oidc.user;
            if (user?.profile?.Email) {
                SessionStorageService.setSessionValue("LoggedInUserEmail", user.profile.Email);
            }
            return user;
        },
        setUser: (user) => {
            store.dispatch({
                type: 'redux-oidc/USER_FOUND',
                payload: user,
            });
            let oidcSessionUrl = "oidc.user:" + window.$config.authenticationUrl + ":" + window.$config.portcqClientId;
            SessionStorageService.removeSessionValue(oidcSessionUrl);
            SessionStorageService.setSessionValue(oidcSessionUrl, JSON.stringify(user));
        },
        getUserId: () => {
            let user = store.getState().oidc.user;
            let userId = (user && user.profile) ? user.profile.sub : "";
            return userId;
        },
        hasUserAccessRights: () => {

            const user = store.getState().oidc.user;
            if (user.profile.IsSuperAdmin && user.profile.IsSuperAdmin.toLowerCase() == 'true') {
                return true;
            }
            if (user.profile.HasAccessToApplication != null && user.profile.HasAccessToApplication != undefined) {
                return user.profile.HasAccessToApplication.toLowerCase() === 'true';
            }
            return false;
        },
        isEmailConfirmed: () => {
            const user = store.getState().oidc.user;
            if (user != undefined && user.profile.EmailConfirmed?.toLowerCase() == 'false') {
                return false;
            }
            return true;
        }
    }

    return UserManager;
};

export { initializeUserManager as initializeUserManager, OIDCService as OIDCService }