import React, { useState, useRef, useEffect, useContext } from "react";
import { Overlay, Popover, Dropdown } from 'react-bootstrap';
import { AiOutlineFileAdd } from "react-icons/ai";
import DocumentUploaderWithModal from "../DocumentUploaderWithModal/DocumentUploaderWithModal";
import { connect } from "react-redux";
import "./TopNavMenu.css";
import { useLocation } from "react-router-dom";
import { DATA_CONSTANT } from "../../constants/DataConstant";
import HeaderToggleButton from '../Header/HeaderToggleButton';
import { useHistory } from "react-router-dom";
import { SessionStorageService } from "../../services/SessionStorageService";
import { OIDCService } from '../../constants/UserManager';
import Swal from 'sweetalert2';
import { RouteDataContext } from "../../Routes";
import { CommonService } from "../../services/CommonService";
import { JARGON_CONSTANT } from "../../constants/JargonConstant";
import { BsChatDots } from "react-icons/bs";
import AssemblyChat from "../AssemblyChat/AssemblyChat";
import { AssemblyChatService } from "../../services/AssemblyChatService";

const TopNavMenu = ({ rfqDetails }) => {
    const [show, setShow] = useState(false);
    const [target, setTarget] = useState(null);
    const [showChat, setShowChat] = useState(false);
    const [chatTarget, setChatTarget] = useState(null);
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
    const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);

    const history = useHistory();
    const ref = useRef(null);

    const location = useLocation();
    const handleMenuIcons = (event) => {
        setShow(!show);
        if (!target)
            setTarget(event.target);
    };

    const handleChatIconClick = (event) => {
        setUnreadMessagesCount(0);
        setShowChat(!showChat);
        if (!chatTarget && event)
            setChatTarget(event.target);
    };

    const routeData = useContext(RouteDataContext);
    const currRoute = routeData.find(function (route) { return route.RouteUrl == location.pathname });

    const isDocumentUploadVisible = (currRoute && currRoute.AllowDocumentUpload && rfqDetails?.assemblyId > 0 && !rfqDetails?.isCloneApplied && !rfqDetails?.isViewMode);
    const isAssemblyChatVisible = isUserLoggedIn && currRoute && currRoute.AllowAssemblyDiscussion && rfqDetails?.assemblyId > 0 && !rfqDetails?.isCloneApplied;

    useEffect(() => {
        checkUser();
    }, []);

    const userSignIn = () => {
        OIDCService.login();
    }

    const userSignOut = () => {
        OIDCService.logout();
    }

    const getUnreadMessageCount = (user) => {
        if (currRoute && currRoute.AllowAssemblyDiscussion && rfqDetails?.assemblyId > 0) {

            let requestObject = {
                ChatNotesIds: [rfqDetails?.assemblyId],
                ChatNotesType: 'Assembly',
                LoginUserEmail: user?.profile?.Email,
                IsExternal: true
            }

            AssemblyChatService.getUnreadMessageCount(requestObject).then((unreadMessageCountResponse) => {

                if (unreadMessageCountResponse?.data?.length > 0) {
                    setUnreadMessagesCount(unreadMessageCountResponse.data[0].unreadCount);
                }
            });

        }
    }

    const checkUser = async () => {
        let user = OIDCService.getUser();
        if (user != null) {
            let hasUserAccessRight = OIDCService.hasUserAccessRights();
            let hasEmailConfirmed = OIDCService.isEmailConfirmed();
            if (hasEmailConfirmed == false && !window.location.pathname.includes('emailActivationPending')) {
                let postLoginRoute = SessionStorageService.getPostLoginUrl();
                if (!postLoginRoute) {
                    SessionStorageService.setPostLoginUrl(window.location.pathname);
                }
                history.push(CommonService.getRoutePath(DATA_CONSTANT.routes.emailActivationPending));
            }
            if (hasUserAccessRight) {
                setIsUserLoggedIn(true);
                getUnreadMessageCount(user);
            }
            else {
                history.push(CommonService.getRoutePath(DATA_CONSTANT.routes.error), 401);
            }
        }
    }

    const handleShow = () => {
        Swal.fire({
            text: JARGON_CONSTANT.jargonObject.LogoutConfirmation,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: DATA_CONSTANT.OrangeColor,
            cancelButtonColor: DATA_CONSTANT.BlueColor,
            confirmButtonText: JARGON_CONSTANT.jargonObject.LogoutLinkText,
            cancelButtonText: JARGON_CONSTANT.jargonObject.CancelButton,
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                userSignOut();
            }
        })
    };

    const handleQuoteHistory = () => {
        history.push(CommonService.getRoutePath(DATA_CONSTANT.routes.quoteHistory));
    }

    return (
        <>


            {isDocumentUploadVisible &&
                <AiOutlineFileAdd ref={target} className="mr-3 icon-cursor TopNavMenuColor" onClick={handleMenuIcons} />
            }
            <Overlay
                show={show}
                target={target}
                rootClose
                onHide={() => setShow(!show)}
                container={ref.current}
                containerPadding={40}
                placement="bottom"
            >
                <Popover id="popover-contained" className="document-content shadow">
                    <Popover.Content>
                        <DocumentUploaderWithModal showUpload={handleMenuIcons} />
                    </Popover.Content>
                </Popover>
            </Overlay>

            {
                isAssemblyChatVisible &&
                <div>
                    {
                        unreadMessagesCount > 0 && <span className="chat-count-bubble"></span>
                    }

                    <BsChatDots ref={chatTarget} className="mr-3" onClick={handleChatIconClick} />
                    <Overlay
                        show={showChat}
                        target={chatTarget}
                        rootClose
                        onHide={() => setShowChat(!showChat)}
                        container={ref.current}
                        containerPadding={40}
                        placement="bottom"
                    >
                        <Popover id="popover-contained" className="assembly-chat-popover shadow">
                            <Popover.Content>
                                <AssemblyChat showChat={handleChatIconClick} />
                            </Popover.Content>
                        </Popover>
                    </Overlay>
                </div>
            }

            <div className="float-right">
                <Dropdown className="cmTopNavMenu TopNavMenuColor">
                    <Dropdown.Toggle as={HeaderToggleButton} id="dropdown-custom-components">
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {!isUserLoggedIn &&
                            <Dropdown.Item eventKey="1" onClick={() => userSignIn()} >{JARGON_CONSTANT.jargonObject.LoginLinkText}</Dropdown.Item>
                        }
                        {isUserLoggedIn && <>
                            {DATA_CONSTANT.IsAnyQuoteFeatureEnabled && <Dropdown.Item eventKey="1" onClick={() => handleQuoteHistory()} >{JARGON_CONSTANT.jargonObject.QuoteHistoryLinkText}</Dropdown.Item>}
                            <Dropdown.Item className="mt-2" eventKey="2" onClick={() => handleShow()} >{JARGON_CONSTANT.jargonObject.LogoutLinkText}</Dropdown.Item>
                        </>
                        }
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    rfqDetails: state.RFQDetails?.rfqDetails
});

export default connect(mapStateToProps)(TopNavMenu);