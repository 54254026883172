import { SET_CLIENT_NAME, SET_CLIENT_CODE } from '../../constants/ActionConstants';

export const setClientName = (clientName) => ({
    type: SET_CLIENT_NAME,
    data: { clientName: clientName }
});

export const setClientCode = (clientCode) => ({
    type: SET_CLIENT_CODE,
    data: { clientCode: clientCode }
});