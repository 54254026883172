import { CLEAR_STORE, SET_PCBNET_QUOTE_DETAILS } from '../../constants/ActionConstants';

const InitialState = {};
export const PCBNetQuoteDetails = (state = InitialState, action) => {
    const { type, data } = action;

    switch (type) {
        case SET_PCBNET_QUOTE_DETAILS:
            return { ...state, pcbNetQuoteDetails: data.pcbNetQuoteDetails };
         case CLEAR_STORE:
            return InitialState;
       default:
            return state;
    }
}