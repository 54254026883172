import React, { forwardRef } from 'react';
import { AiOutlineUser } from 'react-icons/ai';

const HeaderToggleButton = forwardRef(({ children, onClick }, ref) => (
    <div className="cursor-pointer"
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}

        <AiOutlineUser className="float-right pt-lg-1" />

    </div>
));

export default HeaderToggleButton;