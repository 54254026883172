import { RESET_COMPLETED_STEPS, SET_COMPLETED_STEPS, SET_ALL_COMPLETED_STEPS } from '../../constants/ActionConstants';

export const setCompletedSteps = (stepName) => ({
    type: SET_COMPLETED_STEPS,
    data: { stepName: stepName }
});

export const resetCompletedSteps = () => ({
    type: RESET_COMPLETED_STEPS,
    data: {}
});

export const setAllCompletedSteps = (steps) => ({
    type: SET_ALL_COMPLETED_STEPS,
    data: steps
});