import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { trackPromise } from "react-promise-tracker";
import { connect } from "react-redux";
import { RequestFormService } from "../../services/RequestFormService";
import { ConfigurationService } from "../../services/ConfigurationService";
import DocumentUploader from "../../components/ControlComponents/DocumentUploader";
import { RemoveButton } from "../../components/ControlComponents/InputButton/InputButton";
import { MdCancel } from "react-icons/md";
import { DATA_CONSTANT } from "../../constants/DataConstant";
import { CommonService } from "../../services/CommonService";
import './DocumentUploaderWithModal.css';
import Swal from 'sweetalert2';
import { UploadDocumentService } from "../../services/UploadDocumentService";
import { JARGON_CONSTANT } from "../../constants/JargonConstant";

const DocumentUploaderWithModal = ({ assemblyId, salesPersonId, showUpload }) => {
    const [rejectedFiles, setRejectedFiles] = useState([]);
    const allowedFileTypes = DATA_CONSTANT.allowedFileTypes;

    const [assemblyDocumentState, setAssemblyDocumentState] = useState({
        uploadedFiles: [],
        maxAllowFile: 0,
        isTooManyFilesError: false
    });

    const childRef = useRef();
    useEffect(() => {
        trackPromise(getUploadedFiles());
    }, []);

    const getUploadedFiles = async () => {
        let maxFileValueResult = await getMaxNumberOfFileToUpload();
        let maxFiles = parseInt(maxFileValueResult[0].value);
        let data = await RequestFormService.GetRFQDocuments(salesPersonId, assemblyId);
        if (data) {
            let uploadedFileList = [];
            data.map((element) => {
                let fileObj = {
                    name: element.name,
                    documentDetailId: element.id,
                    isFileUploaded: true
                };
                uploadedFileList.push(fileObj);
            });
            setAssemblyDocumentState({
                uploadedFiles: [...uploadedFileList],
                maxAllowFile: maxFiles,
                isTooManyFilesError: false
            });
        }
    }

    const selectedFiles = (documentFile) => {
        if (documentFile.isTooManyFiles) {
            setAssemblyDocumentState({
                ...assemblyDocumentState,
                isTooManyFilesError: true
            });
            return
        }

        if (documentFile.rejectedFiles.length > 0) {
            setRejectedFiles(documentFile.rejectedFiles)
            return
        }

        if (documentFile.acceptedFiles.length == 0)
            return

        const selectedDocuments = [...documentFile.acceptedFiles];
        trackPromise(uploadDocuments(selectedDocuments));
    };

    const uploadDocuments = async (files) => {
        let acceptedFilesToUpload = files.filter(x => x.isFileUploaded !== true);
        if (acceptedFilesToUpload && acceptedFilesToUpload.length > 0) {
            let uploadDocResponse = await UploadDocumentService.uploadDocuments(assemblyId, files);
            if (uploadDocResponse?.length === 0) {
                getUploadedFiles();
            }
        }
    };

    const removeAllUnsupportedFiles = () => {
        childRef.current.handleRemoveAllFile();
        setRejectedFiles([]);
    };

    const removeFileConfirmation = (name) => {
        Swal.fire({
            text: JARGON_CONSTANT.jargonObject.DeleteFileAlert.formatGeneric(name),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: JARGON_CONSTANT.jargonObject.YesButton,
            cancelButtonText: JARGON_CONSTANT.jargonObject.NoButton,
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                trackPromise(
                    removeFile(name)
                );
            }
            showUpload();
        })
    }

    const removeFile = async (name) => {
        let file = assemblyDocumentState.uploadedFiles.find(function (obj) { return obj.name === name });
        if (file) {
            const requestObj = {
                assemblyId: assemblyId,
                userId: salesPersonId,
                documentName: name,
                documentDetailId: file.documentDetailId
            };
            await RequestFormService.DeleteRFQDocument(requestObj)
                .then((result) => {
                    getUploadedFiles();
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            childRef.current.handleRemoveFile(name);
        }
    };

    const handleCancel = () => {
        if (showUpload) {
            showUpload(false);
        }
    };

    const getMaxNumberOfFileToUpload = async () => {
        let maxNumberOfFile = DATA_CONSTANT.ConfigurationKey.MaxNoOfUploadDocuments;
        let configKeys = [maxNumberOfFile];
        return ConfigurationService.getByKeys(configKeys);
    };

    return (
        <Container fluid className="wrapper pl-5">
            <MdCancel className="svg-r icon-cursor" onClick={handleCancel} />
            <Row className="justify-content-md-center">
                <Col md={12} sm={12} xs={12}>
                    <DocumentUploader
                        key={CommonService.generateRandomId(6)}
                        ref={childRef}
                        displayFiles={false}
                        allowedFileTypes={allowedFileTypes}
                        handleFiles={selectedFiles}
                        maxNumberOfFiles={assemblyDocumentState.maxAllowFile}
                        mergedAcceptedFiles={assemblyDocumentState.uploadedFiles}
                        isFileUploadOnDrop={true}
                    />
                </Col>
            </Row>
            <Row className="justify-content-md-center files-view">
                <Col md={12} className="mb-2">
                    <Container>
                        {assemblyDocumentState.isTooManyFilesError && (
                            <h5 className="error-txt mt-3">{`${JARGON_CONSTANT.jargonObject.MaxFilesValidationMessage} ${assemblyDocumentState.maxAllowFile}`}</h5>
                        )}
                        {assemblyDocumentState.uploadedFiles.length > 0 &&
                            <>
                                <Row>
                                <Col md={8} sm={12} xs={12}>
                                    <h5>{JARGON_CONSTANT.jargonObject.UploadedFilesTitle}</h5>
                                    </Col>
                                    <Col md={1} sm={12} xs={12} className="m-1"></Col>
                                </Row>
                                {
                                    assemblyDocumentState.uploadedFiles.map((file, index) => (
                                        <Row key={index}>
                                            <Col md={10} className="pt-1 file-name-text">
                                                {file.name}
                                            </Col>
                                            <Col md={1} sm={12} xs={12} className="m-1 pl-5">
                                                <RemoveButton
                                                    handleClick={() => {
                                                        removeFileConfirmation(file.name);
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    ))
                                }
                            </>
                        }
                        {rejectedFiles.length > 0 && !assemblyDocumentState.isTooManyFilesError && (
                            <Row className="pt-3">
                                <Col md={8}>
                                    <h5>{JARGON_CONSTANT.jargonObject.RejectedFilesTitle}</h5>
                                </Col>
                                <Col md={1} className="ml-2 rm-btn">
                                    <RemoveButton
                                        handleClick={() => removeAllUnsupportedFiles()}
                                    />
                                </Col>
                            </Row>
                        )}
                        {rejectedFiles && !assemblyDocumentState.isTooManyFilesError &&
                            rejectedFiles.map(({ file, errors }, index) => (
                                <Row key={index}>
                                    <Col md={12} lg={12} sm={12} xs={12} className="file-name-text">
                                        <li key={file.path}>
                                            {file.path} - {file.size} bytes
                                            <ul>
                                                {errors.map((e) => (
                                                    <li key={e.code}>{e.message}</li>
                                                ))}
                                            </ul>
                                        </li>
                                    </Col>
                                </Row>
                            ))}

                    </Container>
                </Col>
            </Row>
        </Container>
    );
};

const mapStateToProps = (state) => ({
    assemblyId: state.RFQDetails?.rfqDetails?.assemblyId,
    salesPersonId: state.DefaultConfigs?.defaultConfigs?.salesPersonId
});

export default connect(mapStateToProps, null)(DocumentUploaderWithModal);