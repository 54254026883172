import React from 'react';
import PropTypes from 'prop-types';
import { FormLabel, FormControl } from 'react-bootstrap';
import InfoToolTip from './InfoToolTip';
import { DATA_CONSTANT } from '../../../constants/DataConstant';

const InputTextArea = React.forwardRef(({
    id,
    name,
    value,
    onChange,
    rows,
    isRequired, // Displays * for required field
    errorMessage,
    label,
    helpText, // Displays information tooltip
    helpPlacement, // Placement for tooltip
    className, //Classname for tooltip
    ...props
}, ref) => {

    return (
        <>
            {label && label.trim() &&
                <>
                    <FormLabel> {label} </FormLabel>
                    {isRequired && <span className="error-txt">*</span>}
                    {helpText && helpText.trim() &&
                        <InfoToolTip className={className} helpText={helpText} helpPlacement={helpPlacement} />
                    }
                </>
            }
            <FormControl
                as="textarea"
                id={id}
                name={name}
                value={value || ""}
                onChange={onChange}
                ref={ref}
                rows={rows}
                {...props}
            />
            { errorMessage && errorMessage[name] && errorMessage[name].message &&
                <span className="error-txt">
                    {errorMessage[name].message}
                </span>
            }
        </>
    )
});

InputTextArea.defaultProps = {
    rows: 2,
    className: "",
}

InputTextArea.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    onChange: PropTypes.func,
    rows: PropTypes.number,
    helpText: PropTypes.string,
    placement: PropTypes.oneOf(DATA_CONSTANT.TooltipPlacement),
    className: PropTypes.string,
}


export default InputTextArea;