import React, { useCallback, useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { useDropzone } from "react-dropzone";
import PropTypes from "prop-types";
import { Container, Button, Row, Col } from "react-bootstrap";
import { RemoveButton } from "./InputButton/InputButton";
import { DATA_CONSTANT } from "../../constants/DataConstant";
import { MESSAGE_CONSTANT } from "../../constants/MessageConstant";
import { JARGON_CONSTANT } from "../../constants/JargonConstant";
import { CommonService } from "../../services/CommonService";

const DocumentUploader = forwardRef(({ maxNumberOfFiles, mergedAcceptedFiles, displayFiles, allowedFileTypes, handleFiles, isFileUploadOnDrop }, ref) => {
    let uplodedFiles = mergedAcceptedFiles.length > 0 ? mergedAcceptedFiles : [];
    const [documentFile, setDocumentFile] = useState({ acceptedFiles: uplodedFiles, rejectedFiles: [], isTooManyFiles: false })
    const onDrop = useCallback((newAcceptedFiles, newRejectedFiles) => {
        let totalFiles = 0;
        let filterUploadedFiles = [];
        let uniqueAcceptedFiles = [
            ...new Set(newAcceptedFiles.map((obj) => obj.name)),
        ].map((name) => {
            return newAcceptedFiles.find((obj) => obj.name === name);
        });
        let uniqueRejectedFiles = [
            ...new Set(newRejectedFiles.map((obj) => obj.file.name)),
        ].map((name) => {
            return newRejectedFiles.find((obj) => obj.file.name === name);
        });
        if (isFileUploadOnDrop) {
            filterUploadedFiles = uniqueAcceptedFiles.filter(function (acceptedFile) {
                return !mergedAcceptedFiles.find(function (mergedFile) {
                    return acceptedFile.name === mergedFile.name
                })
            })
        } else {
            filterUploadedFiles = [...documentFile.acceptedFiles, ...uniqueAcceptedFiles];
        }
        let uniqueMergeFiles = [
            ...new Set(filterUploadedFiles.map((obj) => obj.name)),
        ].map((name) => {
            return filterUploadedFiles.find((obj) => obj.file?.name === name || obj.name === name);
        });

        let mergeRejectedFiles = [...documentFile.rejectedFiles, ...uniqueRejectedFiles];

        let uniqueMergeRejectedFiles = [
            ...new Set(mergeRejectedFiles.map((obj) => obj.file.name || obj.name)),
        ].map((name) => {
            return mergeRejectedFiles.find((obj) => obj.file?.name === name || obj.name === name);
        });

        if (isFileUploadOnDrop) {
            totalFiles = (maxNumberOfFiles - (mergedAcceptedFiles.length + uniqueMergeFiles.length))
        } else {
            totalFiles = (maxNumberOfFiles - uniqueMergeFiles.length)
        }

        if (totalFiles > -1 && mergeRejectedFiles.length < maxNumberOfFiles) {
            setDocumentFile({ acceptedFiles: uniqueMergeFiles, rejectedFiles: uniqueMergeRejectedFiles, isTooManyFiles: false })
        } else {
            setDocumentFile({ ...documentFile, isTooManyFiles: true })
        }
    },
        [documentFile.acceptedFiles, documentFile.rejectedFiles]
    );

    const { getRootProps, getInputProps, open } = useDropzone({
        noClick: true,
        noKeyboard: true,
        accept: allowedFileTypes,
        onDrop,
        maxFiles: maxNumberOfFiles,
        maxSize: DATA_CONSTANT.maxFileSize,
        minSize: 1
    });

    useEffect(() => {
        if (handleFiles) {
            handleFiles(documentFile);
        }
    }, [documentFile]);

    const removeFile = (name) => {
        let files = { ...documentFile };
        let removeFileIndex = files.acceptedFiles.findIndex((e) => e.name === name);
        files.acceptedFiles.splice(removeFileIndex, 1);
        files.isTooManyFiles = false;
        setDocumentFile(files);
    };

    const removeAllUnsupportedFiles = () => {
        setDocumentFile({ ...documentFile, rejectedFiles: [] });
    };

    useImperativeHandle(ref, () => ({
        handleRemoveFile(name) {
            removeFile(name);
        },
        handleRemoveAllFile() {
            removeAllUnsupportedFiles();
        },
    }));

    const acceptedFilesView = documentFile.acceptedFiles && documentFile.acceptedFiles.map((file, index) => (
        <Row key={index}>
            <Col md={10} lg={10} sm={12} xs={12} className="file-name-text">
                {file.path} - {file.size} bytes
            </Col>
            <Col md={2} lg={2} sm={12} xs={12} className="mt-2">
                <RemoveButton handleClick={() => removeFile(file.name)} />
            </Col>
        </Row>
    ));

    const rejectedFilesView = documentFile.rejectedFiles && documentFile.rejectedFiles.map(({ file, errors }, index) => (
        <Row key={index}>
            <Col md={12} lg={12} sm={12} xs={12} className="file-name-text">
                <li key={file.path}>
                    {file.path} - {file.size} bytes
                    <ul>
                        {errors.map((e) => (
                            <li key={e.code}>{e.message}</li>
                        ))}
                    </ul>
                </li>
            </Col>
        </Row>
    ));

    return (
        <Container fluid>
            <Row>
                <Col>
                    <div {...getRootProps({ className: "dropzone file-uploder" })}>
                        <div className="margin-35">
                            <input {...getInputProps({})} />
                            <div className="header-txt">
                                <Row>
                                    <Col>{CommonService.getJargonValue(JARGON_CONSTANT.keys.FileImportMessage1)}</Col>
                                </Row>
                                <Row>
                                    <Col> {CommonService.getJargonValue(JARGON_CONSTANT.keys.FileImportMessage2)}</Col>
                                </Row>
                            </div>
                            <Button type="button" onClick={open} className="btn-generic-primary">
                                {CommonService.getJargonValue(JARGON_CONSTANT.keys.ChooseFileButton)}
                            </Button>
                            <div className="mt-3">({CommonService.getJargonValue(JARGON_CONSTANT.keys.MaxFileUploadMessage).toString().formatGeneric(maxNumberOfFiles)})</div>
                        </div>
                    </div>
                </Col>
            </Row>
            {displayFiles === true && (
                <Row className="mt-3 ml-md-3 ml-lg-0">
                    <Col>
                        <Row>
                            <Col>
                                {acceptedFilesView.length > 0 && (
                                    <Row>
                                        <Col md={5}>
                                            <h4>Attached files</h4>
                                        </Col>
                                    </Row>
                                )}
                                {acceptedFilesView}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {rejectedFilesView.length > 0 && !documentFile.isTooManyFiles && (
                                    <>
                                        <Row className="pt-5">
                                            <Col md={10}>
                                                <h4>Rejected files</h4>
                                            </Col>
                                            <Col md={2}>
                                                <RemoveButton
                                                    handleClick={() => removeAllUnsupportedFiles()}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>{rejectedFilesView}</Row>
                                    </>
                                )}

                                {documentFile.isTooManyFiles && (
                                    <h5 className="error-txt mt-3">{`${JARGON_CONSTANT.jargonObject.MaxFilesValidationMessage} ${maxNumberOfFiles}`}</h5>
                                )}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            )}
        </Container>
    );
});

DocumentUploader.defaultProps = {
    displayFiles: false,
    isFileUploadOnDrop: false,
    maxNumberOfFiles: DATA_CONSTANT.maxNumberOfFiles
};

DocumentUploader.propTypes = {
    displayFiles: PropTypes.bool,
    isFileUploadOnDrop: PropTypes.bool,
    allowedFileTypes: PropTypes.array.isRequired,
    handleFiles: PropTypes.func.isRequired,
    maxNumberOfFiles: PropTypes.number
};

export default DocumentUploader;