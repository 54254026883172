/// <reference path="../services/checklistbuilderservice.js" />
export const DATA_CONSTANT = {

    allowedFileTypes: [
        'image/*',
        'application/pdf',
        '.csv',
        'application/msword',
        'application/json',
        'text/plain',
        '.zip',
        '.rar',
        '.7zip',
        '.xlsx',
        '.docx',
        '.doc',
        '.gbr',
        '.xls'
    ],
    maxFileSize: 52428800  /*Bytes- 50 MB*/,
    maxFiles: 5,
    patterns: {
        email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        name: /^[a-zA-Z0-9\[\]\\/{};,!@#$%^&*~':?) (+=._-]+$/,
        nameWithSpecialCharacters: '^[\\p{L}\\p{N}\\p{Sc}\\p{So}\\p{M}\\p{Zs}\\p{P}^~+=]+$',
        //Number starting from 1 to 9 and allow up to 99999999
        quantity: /^[1-9][0-9]{0,7}$/,
        //Number starting from 1 to 9 and allow up to 999
        totalTurnTime: /^[1-9][0-9]{0,2}$/,
        laborQuestion: /^[0-9]{1,4}(\.[0-9]{1,2})?$/,
        checkListBuilderNumber: /^[0-9]*$/,
        dateFormat: "MM/DD/YYYY",
        assemblyNumber: /^[a-zA-Z0-9-!@$%^#&{}()_+;:,'.= ]{0,50}$/,
        assemblyName: /^[a-zA-Z0-9-!@$%^#&*/{}()_+;:,'.=~ ]+$/,
        shippingCharge: /^[0-9]{1,5}(\.[0-9]{0,2})?$/,
        twoDecimalPointPercentage: /^((?:|0|[1-9]\d?|100)(?:\.\d{1,2})?)$/,
        joditDefaultTags: /<p[^>]*>(&nbsp;|\s+|<br\s*\/?>)*<\/p>/g
    },

    stepNames: {
        RFQ: 'RFQ',
        BOM: 'BOM',
        Labor: 'Labor',
        PCB: 'PCB',
        ReviewBOM: 'ReviewBOM',
        IsComponentPricingDone: 'IsComponentPricingDone',
        AssemblyDetail: 'AssemblyDetail',
        GetQuote: 'GetQuote',
        Checkout: 'Checkout',
        CheckoutBillingShipping: 'CheckoutBillingShipping',
        CheckoutPayment: 'CheckoutPayment',
        CheckoutVerify: 'CheckoutVerify',
        Completed: 'Completed',
        ContactDetails: 'ContactDetails'
    },

    guidelinesFiles: {
        BOM_format_guidelines: 'BOM_Best_Practices.pdf',
        BOM_template: 'AAPCB_BOM_Template.xlsx'
    },

    validExtensionForBomFile: ['xlsx', 'xls', 'csv'],

    bomFileType: [{ extension: 'xls', type: 'application/vnd.ms-excel' },
    { extension: "xlsx", type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
    { extension: "csv", type: 'text/csv' }],

    FileType: {
        BOM: 'BOM'
    },

    //Array to bind file type drop down list
    arrayFileType: [
        "Select",
        "BOM",
    ],

    timeUOMs: [
        { label: 'Day(s)', value: 'Days' },
        { label: 'Week(s)', value: 'Weeks' },
    ],

    ConfigurationKey: {
        IntercomAppId: 'IntercomAppId',
        GoogleAnalyticsTagId: 'GoogleAnalyticsTagId',
        PCBPartClass: 'PCBPartClass',
        StripePublishableKey: 'StripePublishableKey',
        AllowMultipleJobTypeSelection: 'AllowMultipleJobTypeSelection',
        MaxNoOfUploadDocuments: 'MaxNoOfUploadDocuments',
        ClientName: 'ClientName',
        ThemeName: 'ThemeName',
        ContactNumber: 'ContactNumber',
        QuoteCQClientCode: 'QuoteCQClientCode',
        MinimumTurnTime: 'MinimumTurnTime',
        AdjustedTurnTimeToolTipText: 'AdjustedTurnTimeToolTipText',
        RejectTurnTimeConformationText: 'RejectTurnTimeConformationText',
        ApproveTurnTimeConformationText: 'ApproveTurnTimeConformationText',
        IsLogRocketEnabled: 'IsLogRocketEnabled',
        LogRocketAppId: 'LogRocketAppId',
        DefaultTurnTimeForBOMAnalysis: 'DefaultTurnTimeForBOMAnalysis',
        IsBomAnalysisEnabled: 'IsBomAnalysisEnabled',
        IsGetAQuoteEnabled: 'IsGetAQuoteEnabled',
        IsQuickPriceEnabled: 'IsQuickPriceEnabled',
        DefaultFirstName: 'DefaultFirstName',
        DefaultLastName: 'DefaultLastName',
        DefaultEmail: 'DefaultEmail',
        DefaultPhone: 'DefaultPhone',
        DefaultCompany: 'DefaultCompany',
        DefaultAssemblyName: 'DefaultAssemblyName',
        DefaultAssemblyNumber: 'DefaultAssemblyNumber',
        DefaultTurnTimeForQuickPrice: 'DefaultTurnTimeForQuickPrice',
        DefaultRev: 'DefaultRev',
        DefaultQuoteType: 'DefaultQuoteType',
        ReportRedashId: 'ReportRedashId',
        BOMHealthWidgetUrl: 'BOMHealthWidgetUrl',
        IsBOMHealthEnabled: 'IsBOMHealthEnabled',
        BOMHealthReportAPIAccessToken: 'BOMHealthReportAPIAccessToken',
        BOMHealthLastUpdatedDays: 'BOMHealthLastUpdatedDays',
        VAT: 'VAT',
        IsBomHealthHelpIconVisible: 'IsBomHealthHelpIconVisible',
        RemoveActivityWhenSwitchIsOffFeatureEnabled: 'RemoveActivityWhenSwitchIsOffFeatureEnabled',
        DefaultQuantity: 'DefaultQuantity',
        PCBNetUrl: 'PCBNetUrl',
        MaximumQuantityCount: 'MaximumQuantityCount',
        IsPricingColumnsVisibleInReviewBOM: 'IsPricingColumnsVisibleInReviewBOM',
        HubSpotAppId: 'HubSpotAppId'
    },

    defaultTimeUOMUnit: "Day(s)",
    TooltipPlacement: ['right', 'left', 'top', 'bottom'],
    ITARProcessingRequired: 'ITAR processing required',
    FileIconNotDispalyOnRoute: ["/rfq", "/customquote"],
    RfqRouteName: 'rfq',
    routes: {
        error: "error",
        customQuote: "customquote",
        complete: "complete",
        quoteHistory: "quotehistory",
        checkout: "checkout",
        jobtype: "jobtype",
        reviewbom: "reviewbom",
        scrubmybom: "scrubmybom",
        emailActivationPending: "emailActivationPending",
        bomHealthMonitorHistory: "bomhealthmonitorhistory",
        quoteSummary: "quotesummary",
        quoteComplete: "quotecomplete",
        bomHealthMonitor: "bomhealthmonitor",
        backToPCBNet: "backtopcbnet"
    },
    rfqSteps: [],

    AssemblyFields: {
        ExternalUserId: 'ExternalUserId'
    },

    SectionName: {
        home: "Home",
        pricingCompiling: "PricingCompiling",
        scrubMyBom: "scrubMyBom",
        createAQuoteDescription: "CreateAQuoteDescription",
        bomAnalysisDescription: "BOMAnalysisDescription",
        quickPriceDescription: "QuickPriceDescription",
        getBOMHealthDescription: "GetBOMHealthDescription"
    },

    DriverCountType: {
        Blank: "Blank",
        Fixed: "Fixed",
        Recommended: "Recommended"
    },

    DriverCountSubType: {
        Static: "Static",
        Formula: "Formula"
    },

    ToastMessageVariant: {
        Success: 'success',
        Error: 'danger',
        Warning: 'warning',
        Info: 'info'
    },

    //RFQStatus: { InProgress: 'In Progress', Completed: 'Order Placed', CustomQuote: 'Custom', BOMAnalysis: 'BOM Analysis' },

    AlternateSource: {
        CQSuggestions: 'CQSuggestions',
        Manual: 'Manual'
    },

    DataIndex: {
        QuoteCQJobTypeName: 'quoteCQJobTypeName',
        JobTypeDescription: 'jobTypeDescription',
        Title: 'title',
        DisplayName: 'displayName',
        ShippingMethod: 'shippingMethodName',
        ShippingCharge: 'shippingCharge',
        ScreenName: 'screenName',
        Description: 'description',
        CustomQuoteValues: 'customQuoteValues'
    },
    MarketingContents: {
        HTMLContentLabel: 'HTML Content',
        SectionNameLabel: 'Section Name'
    },

    GridScroll: { y: '350px' },
    ScreenNamesForConfiguration: [
        { name: 'AssemblyDetail', displayValue: 'Assembly Detail' },
        { name: 'Labor', displayValue: 'Labor' },
    ],
    SectionNamesForConfiguration: [
        { name: 'Home', displayValue: 'Home', isForMarketingSection: true, isForChecklistSection: false },
        { name: 'PricingCompiling', displayValue: 'Pricing Compiling', isForMarketingSection: true, isForChecklistSection: false },
        { name: 'ScrubMyBOM', displayValue: 'Scrub My BOM', isForMarketingSection: true, isForChecklistSection: false },
        { name: 'AssemblyDetail', displayValue: 'Assembly Detail', isForMarketingSection: false, isForChecklistSection: true },
        { name: 'Labor', displayValue: 'Labor', isForMarketingSection: false, isForChecklistSection: true },
        { name: 'CreateAQuoteDescription', displayValue: 'Create A Quote', isForMarketingSection: true, isForChecklistSection: false },
        { name: 'BOMAnalysisDescription', displayValue: 'BOM Analysis', isForMarketingSection: true, isForChecklistSection: false },
        { name: 'QuickPriceDescription', displayValue: 'Quick Price', isForMarketingSection: true, isForChecklistSection: false },
        { name: 'GetBOMHealthDescription', displayValue: 'Get BOM Health', isForMarketingSection: true, isForChecklistSection: false }
    ],

    BomHealthRFQInfo: null,
    PortCQApplicationName: "PortCQ",
    ClientCode: "",
    BOMHealthScheduleInterval: [
        { label: 'Select', value: '' },
        { label: '1 Week', value: 7 },
        { label: '2 week', value: 14 },
        { label: '3 week', value: 21 },
    ],
    IsCustomDomainClient: false,
    CurrencyMaster: {
        "AED": "د.إ",
        "ARS": "$",
        "AUD": "$",
        "BGN": "Лв.",
        "BRL": "R$",
        "BSD": "B$",
        "CAD": "$",
        "CHF": "CHf",
        "CLP": "$",
        "CNY": "¥",
        "COP": "$",
        "CZK": "Kč",
        "DKK": "Kr.",
        "DOP": "$",
        "EGP": "E£",
        "EUR": "€",
        "FJD": "FJ$",
        "GBP": "£",
        "GTQ": "Q",
        "HKD": "$",
        "HRK": "kn",
        "HUF": "Ft",
        "IDR": "Rp",
        "ILS": "₪",
        "INR": "₹",
        "ISK": "kr",
        "JPY": "¥",
        "KRW": "₩",
        "KZT": "₸",
        "MVR": "ރ.",
        "MXN": "$",
        "MYR": "RM",
        "NOK": "kr",
        "NZD": "$",
        "PAB": "B/.",
        "PEN": "S/",
        "PHP": "₱",
        "PKR": "₨",
        "PLN": "zł",
        "PYG": "₲",
        "RON": "lei",
        "RUB": "₽",
        "SAR": "SR",
        "SEK": "kr",
        "SGD": "$",
        "THB": "฿",
        "TRY": "₺",
        "TWD": "$",
        "UAH": "₴",
        "USD": "$",
        "UYU": "$",
        "ZAR": "R",
        "LKR": "Rs",
        "RMB": "¥",
    },
    IsAnyQuoteFeatureEnabled: false,
    QuoteFeatures: [],
    IsPCBNetClient: false,
    IsGoogleAnalyticsEnabled: false
};