import React from "react";
import Header from '../components/Header/Header';
import ConfigurationSideMenu from "../components/ConfigurationSideMenu/ConfigurationSideMenu";

const ConfigurationsLayout = ({ children }) => {
    return (
        <div className="configurations-layout d-flex flex-column flex-grow-1">
            <Header />
            <div id="configuration-wrapper1" className="d-flex overflow-auto position-relative flex-grow-1">
                <ConfigurationSideMenu />
                <div className="d-flex flex-column cm-flex-1 border-top">
                    {children}
                </div>
            </div>
        </div>
    )
};

export default ConfigurationsLayout;
