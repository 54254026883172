import React, { useEffect } from 'react';
import { Route, useHistory, useLocation } from 'react-router-dom';
import { OIDCService } from '../../constants/UserManager';
import { connect } from "react-redux";
import { CommonService } from "../../services/CommonService";
import { DATA_CONSTANT } from "../../constants/DataConstant";

const PrivateRoute = ({
    component: Component,
    clientInfo,
    layout: Layout,
    ...props
}) => {
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        if (props.title) {
            document.title = `${props.title} | ${clientInfo.clientName}`;
        }
    });

    let isUserLoggedIn = false;
    const checkUserLoggedIn = () => {
        if (location.pathname.indexOf('callback') < 0) {
            isUserLoggedIn = OIDCService.login();
            if (isUserLoggedIn) {
                let hasAccessRights = OIDCService.hasUserAccessRights();
                if (hasAccessRights === false) {
                    history.push(CommonService.getRoutePath(DATA_CONSTANT.routes.error), 401);
                }
            }
        }
    }

    checkUserLoggedIn();
    if (Layout) {
        return (
            isUserLoggedIn && (
                <Route  {...props} >
                    <Layout>
                        <Component {...props} />
                    </Layout>
                </Route>
            )
        )
    }
    return (
        isUserLoggedIn && (<Route  {...props}>            
            <Component {...props} />            
        </Route>)
    )
}


const mapStateToProps = (state) => ({
    clientInfo: state.Client
});

export default connect(mapStateToProps)(PrivateRoute);
