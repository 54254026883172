import React from 'react';
import { DATA_CONSTANT } from '../../constants/DataConstant';
import './Logo.css'

const Logo = () => (
    <a href={window.$config.logoRedirectUrl ? window.$config.logoRedirectUrl : (window.location.origin + '/' + DATA_CONSTANT.ClientCode)} target="_blank" className="navbar-brand logo p-0">
        <div className="logo-image"></div>
    </a>
);

export default Logo