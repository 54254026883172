import React, { useState, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { Provider as ReduxProvider } from "react-redux";
import { configureStore } from "./redux/store";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { OidcProvider } from "redux-oidc";
import { initializeUserManager } from "./constants/UserManager";
import { SignalrProvider } from './services/SignalrService';
import { ConfigurationService } from "./services/ConfigurationService";
import Swal from "sweetalert2";
import { DATA_CONSTANT } from './constants/DataConstant';
import { MESSAGE_CONSTANT } from './constants/MessageConstant';

const MainComponent = (props) => {

    const [isLoaded, setIsLoaded] = useState(false);

    const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
    const store = configureStore();
    const persistor = persistStore(store);
    let clientCode = window.location.pathname.split("/")[1];

    useEffect(() => {
        ConfigurationService.isClientExist(clientCode).then((data) => {
            if (data && data.isExists) {
                DATA_CONSTANT.ClientCode = data.clientCode;
                DATA_CONSTANT.IsCustomDomainClient = data.isCustomDomainClient;
                getApplicationConfigurations();
                DATA_CONSTANT.IsPCBNetClient = data.isPCBNetClient;
            }
            else {
                showInvalidURLAlert();
            }
        });
    }, []);

    const showInvalidURLAlert = () => {
        Swal.fire({
            html: `<b>${MESSAGE_CONSTANT.invalidURLMessage.messageTitle}</b><br>${MESSAGE_CONSTANT.invalidURLMessage.messageContent}</div>`,
            icon: 'warning',
            showConfirmButton: false,
            allowOutsideClick: false
        }).then((result) => {

        });
    }

    const getApplicationConfigurations = async () => {
        await ConfigurationService.getApplicationConfigurations()
            .then(configData => {
                window.$config = configData.config;
                SignalrProvider.initializeConnection();
                setIsLoaded(true);
            });
    }

    if (isLoaded) {
        let userManager = initializeUserManager(window.$config);
        return (
            <ReduxProvider store={store}>
                <OidcProvider store={store} userManager={userManager}>
                    <PersistGate persistor={persistor}>
                        <BrowserRouter basename={baseUrl}>
                            <App {...props} />
                        </BrowserRouter>
                    </PersistGate>
                </OidcProvider>

            </ReduxProvider>
        );
    }

    return (
        <div className="app-loading">
            <h3>Application is loading...</h3>
        </div>
    );
}

export default MainComponent;