export const MESSAGE_CONSTANT = {
    //quantity: {
    //    required: "Quantity is required.",
    //    invalid: "Please insert valid Quantity."
    //},
    //turnTime: {
    //    required: "Total Turn Time is required.",
    //    invalid: "Please insert valid Total Turn Time.",
    //    minLength: "Turn Time should not be less than {0}."
    //},
    //contactInfo: {
    //    name: {
    //        required: "Name is required.",
    //        invalid: "Please insert valid Name."
    //    },
    //    firstName: {
    //        required: "First Name is required.",
    //        invalid: "Please insert valid First Name."
    //    },
    //    lastName: {
    //        required: "Last Name is required.",
    //        invalid: "Please insert valid Last Name."
    //    },
    //    email: {
    //        required: "Email is required.",
    //        invalid: "Please insert valid Email."
    //    },
    //    company: {
    //        invalid: "Please insert valid Company name."
    //    },
    //    phone: {
    //        required: "Phone is required.",
    //        invalid: "Please insert valid Phone."
    //    }
    //},
    //labor: {
    //    laborQuestion: {
    //        required: "Field is required.",
    //        invalid: "Please enter a valid value.",
    //        maxValue: "Value must be less than 5 digits."
    //    },
    //    laborNotConfigured: "Build requirements is not configured.",
    //    onlyReadonlyQuestions: "Looks like all of your labor activities are already configured, you can move to the next page.",
    //},
    //documntUploader: {
    //    isFileUpload: {
    //        required: "Please add your Gerbers, XYRS data and BOM.",
    //    },
    //},
    //bomFile: {
    //    required: "Please upload csv/xls/xlsx type file and mark it as BOM.",
    //},
    //tooManyFiles: "Too many files. Maximum number of files is",
    validationMessage: {
        //required: " is required.",
        //invalid: "Please insert valid ",
        maxLength50: "Value must be less than 51 characters.",
        maxLength100: "Value must be less than 101 characters.",
        maxLength250: "Value must be less than 251 characters.",
        maxLength500: "Value must be less than 501 characters."
    },
    //CheckListBuilder: {
    //    maxValue: "Value cannot contain more than {0} characters.",
    //    required: "{0} Field is required.",
    //    invalid: "Please insert valid {0}.",
    //    minValue: "Value must be greater than {0} digits.",
    //},
    //reviewBOM: {
    //    //requiredMethodOfSupply: "Please select Method Of Supply for LineItems "
    //},
    //required: "is required.",
    //validation: {
    //    maxValue: "{0} cannot contain more than {1} characters.",
    //    required: "{0} is required.",
    //    invalid: "Please insert valid {0}.",
    //    minValue: "Value must be greater than {0} digits.",
    //},
    //accountNumber: {
    //    invalid: "Invalid account number.",
    //    required: "Account Number is required.",
    //},
    //Payment: {
    //    invalidAmount: "Amount should be greater than 0."
    //},
    //suggestAlternate: {
    //    required: "Please select at least one alternate part."
    //},
    //alternates: {
    //    duplicate: "Duplicate part number found : {0}.",
    //    requireAlternateForLineItems: "Alternate needs to be added for lineitem(s) {0}."
    //},
    //customQuoteWarning: "This would require to be a custom quote. Are you sure you want to continue?",
    //DeleteFile: "Are you sure you want to delete {0}?",
    //DeleteQuantity: "Are you sure you want to delete this quantity?",
    //DeleteTurnTime: "Are you sure you want to delete this turntime?",
    //MinimumOneQuantityRequired: "Minimum one quantity and turntime is required.",
    //MinimumOneTurnTimeRequired: "Minimum one turntime is required per quantity.",
    //StartNewQuoteConfirmation: "Are you sure you want to create a new quote?",
    //assemblyDetailNotConfigured: "Assembly details are not configured, you can move to the next page.",
    //noPCBLineAdded: "No PCB line(s) added in the BOM. Click {0} button to move to the next page.",
    //navigationBlockMessage: "You may have unsaved changes. Are you sure you want to leave this page without saving first?",
    successMessage: "Success!",
    terminologyUpdatedSuccessfully: "Terminology(s) updated successfully!",
    //customQuoteWarningForChecklist: "{0} field(s) selection would require to be a custom quote. Are you sure you want to continue?",
    //reorderRFQ: "Are you sure you want to {0} this quote?",
    miscConfigurationUpdatedSuccessfully: "Misc Configuration(s) updated successfully!",
    jobTypesUpdatedSuccessfully: "Jobtype(s) updated successfully!",
    rfqStepsUpdatedSuccessfully: "RFQ Step(s) updated successfully!",
    shippingMethodsUpdatedSuccessfully: "Shipping Method(s) updated successfully!",
    marketingContentUpdatedSuccessfully: "Marketing Content updated successfully!",
    checkListDetailsUpdatedSuccessfully: "Checklist detail updated successfully!",
    configurations: {
        invalid: "Please insert valid ",
        required: " is required."
    },
    invalidURLMessage: {
        messageTitle: "Invalid URL",
        messageContent: "This URL you are trying to access is invalid.Please request a valid URL."
    }
};
