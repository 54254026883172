import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { connect } from "react-redux";

const PublicRoutes = ({ component: Component, clientInfo, layout: Layout, ...props }) => {
    useEffect(() => {
        if (props.title) {
            document.title = `${props.title} | ${clientInfo.clientName}`;
        }
    });
    if (Layout) {
        return (
            <Route {...props}>
                <Layout>
                    <Component {...props} />
                </Layout>
            </Route>
        )
    }
    return (
        <Route {...props}>
            <Component {...props} />
        </Route>
    )
}

const mapStateToProps = (state) => ({
    clientInfo: state.Client
});

export default connect(mapStateToProps)(PublicRoutes);