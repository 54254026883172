import { CommonHttpService } from './CommonHttpService';
import { URL_CONSTANT } from '../constants/UrlConstant';

export const AccountService = {

    isUserPortCQAdmin: (userId) => {

        const url = `${URL_CONSTANT.Account.IsUserPortCQAdmin}?userId=${userId}`;
        return CommonHttpService.httpGet(url);
    }
}