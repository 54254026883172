import { DATA_CONSTANT } from "../constants/DataConstant";
import { CommonService } from "./CommonService";

export const CommonHttpService = {

    httpGet: (requestURL, parameters) => new Promise((resolve, reject) => {

        const options = {
            method: 'get',
            headers: {
                'content-type': 'application/json; charset=UTF-8',
                'Authorization': "Basic d80c83ac1fd54284a3f5c54a18e60ff8",
                'X-XSRF-TOKEN': CommonService.getCookie('X-CSRF-FORM-TOKEN'),
                'ClientCode': DATA_CONSTANT.ClientCode,
                'UserEmail': CommonService.getUserEmail()
            }
        };
        if (parameters && typeof (parameters) === "object") {
            Object.keys(parameters).forEach((property) => {
                requestURL += requestURL.indexOf('?') > -1 ? "&" : "?";
                requestURL += `${property}=${encodeURIComponent(parameters[property])}`;
            });
        }

        fetch(requestURL, options)
            .then(async response => {

                const data = await response.json();

                if (!response.ok) {
                    // get error message from body or default to response statusText
                    const error = (data && data.message) || response.statusText;
                    console.log(error);

                    reject(error);
                } else {
                    resolve(data.data);
                }

            }).catch(error => {
                console.log(error);
                reject(error);
            });
    }),

    httpPost: (requestURL, requestBody) => new Promise((resolve, reject) => {

        const options = {
            method: 'post',
            headers: {
                'content-type': 'application/json; charset=UTF-8',
                'Authorization': "Basic d80c83ac1fd54284a3f5c54a18e60ff8",
                'X-XSRF-TOKEN': CommonService.getCookie('X-CSRF-FORM-TOKEN'),
                'ClientCode': DATA_CONSTANT.ClientCode,
                'UserEmail': CommonService.getUserEmail()
            },
            body: JSON.stringify(requestBody)
        }

        fetch(requestURL, options)
            .then(async response => {

                const data = await response.json();

                if (!response.ok) {
                    // get error message from body or default to response statusText
                    const error = (data && data.message) || response.statusText;
                    console.log(error);

                    reject(error);
                } else {
                    resolve(data.data);
                }

            }).catch(error => {
                console.log(error);
                reject(error);
            });

    }),

    httpFilePost: (requestURL, fileData) => new Promise((resolve, reject) => {

        const options = {
            method: 'post',
            headers: {
                // 'content-type': 'multipart/form-data',
                // 'content-type': 'application/json',
                'Accept': 'application/json',
                'Authorization': "Basic d80c83ac1fd54284a3f5c54a18e60ff8",
                'X-XSRF-TOKEN': CommonService.getCookie('X-CSRF-FORM-TOKEN'),
                'ClientCode': DATA_CONSTANT.ClientCode,
                'UserEmail': CommonService.getUserEmail()
            },
            body: fileData
        }

        fetch(requestURL, options)
            .then(async response => {

                const data = await response.json();

                if (!response.ok) {
                    // get error message from body or default to response statusText
                    const error = (data && data.Message) || response.statusText;
                    console.log(error);

                    reject(error);
                } else {
                    resolve(data);
                }

            }).catch(error => {
                console.log(error);
                reject(error);
            });
    }),

    httpPut: (requestURL, requestBody) => new Promise((resolve, reject) => {

        const options = {
            method: 'put',
            headers: {
                'content-type': 'application/json; charset=UTF-8',
                'Authorization': "Basic d80c83ac1fd54284a3f5c54a18e60ff8",
                'X-XSRF-TOKEN': CommonService.getCookie('X-CSRF-FORM-TOKEN'),
                'ClientCode': DATA_CONSTANT.ClientCode,
                'UserEmail': CommonService.getUserEmail()
            },
            body: JSON.stringify(requestBody)
        }

        fetch(requestURL, options)
            .then(async response => {

                const data = await response.json();

                if (!response.ok) {
                    // get error message from body or default to response statusText
                    const error = (data && data.message) || response.statusText;
                    console.log(error);
                    reject(error);
                } else {
                    resolve(data);
                }

            }).catch(error => {
                console.log(error);
                reject(error);
            });
    }),

    DownLoadFile: (requestURL, filename) => new Promise((resolve, reject) => {
        const options = {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Authorization': "Basic d80c83ac1fd54284a3f5c54a18e60ff8",
                'X-XSRF-TOKEN': CommonService.getCookie('X-CSRF-FORM-TOKEN'),
                'ClientCode': DATA_CONSTANT.ClientCode,
                'UserEmail': CommonService.getUserEmail()
            },
        }

        fetch(requestURL, options)
            .then(res => {
                if (!res.ok) {
                    const error = res.statusText;
                    console.log(error);
                    reject(error);
                } else {
                    return res.blob();
                }
            }).then(blob => {
                if (blob) {
                    const url = window.URL.createObjectURL(
                        new Blob([blob]),
                    );
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', filename);
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);
                }
            }).catch(err => {
                console.error(err);
                reject(err);
            })
    }),

    DownloadReport: (requestURL, requestBody) => new Promise((resolve, reject) => {
        var fileName;
        const options = {
            method: 'post',
            headers: {
                'content-type': 'application/json; charset=UTF-8',
                'Authorization': "Basic d80c83ac1fd54284a3f5c54a18e60ff8",
                'X-XSRF-TOKEN': CommonService.getCookie('X-CSRF-FORM-TOKEN'),
                'ClientCode': DATA_CONSTANT.ClientCode,
                'UserEmail': CommonService.getUserEmail()
            },
            body: JSON.stringify(requestBody)
        }
        fetch(requestURL, options)
            .then(async response => {
                if (response.ok) {
                    var header = response.headers.get('Content-Disposition');
                    var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    var matches = filenameRegex.exec(header);
                    if (matches !== null && matches[1]) {
                        fileName = decodeURIComponent(matches[1].replace(/['"]/g, ''));
                    }
                    return await response.blob();
                } else {
                    const error = response.statusText;
                    console.log(error);
                    reject(error);
                }
            }).then(blob => {
                if (blob) {
                    const url = window.URL.createObjectURL(
                        new Blob([blob]),
                    );
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', fileName);
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);
                    resolve();
                }
            }).catch(err => {
                reject(err);
            });
    }),
    httpFilePutToS3: (requestURL, fileData) => new Promise((resolve, reject) => {
        const options = {
            method: 'PUT',
            headers: {
                'Content-Type': '',
                'ClientCode': DATA_CONSTANT.ClientCode,
                'UserEmail': CommonService.getUserEmail()
            },
            body: fileData
        }

        fetch(requestURL, options)
            .then(async data => {
                if (!data.ok) {
                    // get error message from body or default to response statusText
                    const error = (data && data.Message) || data.statusText;
                    console.log(error);
                    reject(error);
                } else {
                    resolve(data);
                }
            }).catch(error => {
                console.log(error);
                reject(error);
            });
    })
}