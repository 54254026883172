export const SELECT_JOBTYPE = 'SELECT_JOBTYPE';

export const SET_DEFAULT_CONFIGS = 'SET_DEFAULT_CONFIGS';

export const SET_RFQ_DETAILS = 'SET_RFQ_DETAILS';

export const CLEAR_STORE = 'CLEAR_STORE';

export const SET_COMPLETED_STEPS = 'SET_COMPLETED_STEPS';

export const RESET_COMPLETED_STEPS = 'RESET_COMPLETED_STEPS';

export const SET_BOM_FILES = 'SET_BOM_FILES';

export const CLEAR_JOBTYPES = 'CLEAR_JOBTYPES';

export const SET_PRICING_RUNNING_STATUS = 'SET_PRICING_STATUS';

export const CLEAR_PRICING_RUNNING_STATUS = 'CLEAR_PRICING_STATUS';

export const SET_BOMID = "SET_BOMID";

export const SET_IS_CUSTOMQUOTE_REQUIRES = "SET_IS_CUSTOMQUOTE_REQUIRES";

export const SET_CLIENT_NAME = "SET_CLIENT_NAME";

export const SET_CLIENT_CODE = "SET_CLIENT_CODE";

export const SET_EXTERNAL_USERID = "SET_EXTERNAL_USERID";


export const SET_ALL_COMPLETED_STEPS = 'SET_ALL_COMPLETED_STEPS';

export const SET_PCBNET_QUOTE_DETAILS = 'SET_PCBNET_QUOTE_DETAILS';