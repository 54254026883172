import React, { useEffect, useState } from 'react';
import Logo from '../Logo/Logo';
import './Header.css';
import { Container, Row, Col } from 'react-bootstrap';
import TopNavMenu from "../Navigation/TopNavMenu";
import { OIDCService } from '../../constants/UserManager';
import { DATA_CONSTANT } from '../../constants/DataConstant';

const Header = () => {

    const [loggedInUserName, setLoggedInUserName] = useState(null);

    useEffect(() => {

        let user = OIDCService.getUser();
        if (user != null) {
            // If user logged in then user id is sub
            setLoggedInUserName(user.profile ? user.profile.FirstName : null);
        }
    }, [])

    return (
        <Container fluid className="sticky-top">
            <header>
                <Row className="cm-header-row">
                    <Col md={9} className="div-logo" >
                        <Logo />
                    </Col>
                    <Col md={3} className="profile">
                        {
                            !DATA_CONSTANT.IsPCBNetClient &&
                            <div className="float-right pt-4 pt-lg-1">
                                {loggedInUserName && <div className="user-name"><span>Hello, {loggedInUserName}</span></div>}

                                <TopNavMenu />
                            </div>
                        }
                    </Col>
                </Row>
            </header>
        </Container>
    );
}

export default Header