import { CommonHttpService } from './CommonHttpService';
import { URL_CONSTANT } from '../constants/UrlConstant';

export const AssemblyChatService = {

    getAssemblyChat: (assemblyId, loginUserEmail) => {
        const url = `${URL_CONSTANT.AssemblyChat.GetAssemblyChat}?assemblyId=${assemblyId}&&loginUserEmail=${loginUserEmail}`;
        return CommonHttpService.httpGet(url);
    },
    saveAssemblyChatMessage: (data) => {
        return CommonHttpService.httpPost(URL_CONSTANT.AssemblyChat.SaveAssemblyChatMessage, data);
    },
    getUnreadMessageCount: (data) => {
        return CommonHttpService.httpPost(URL_CONSTANT.AssemblyChat.GetUnreadMessageCount, data);
    }
}