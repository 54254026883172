import { CommonHttpService } from './CommonHttpService';
import { URL_CONSTANT } from '../constants/UrlConstant';

export const ConfigurationService = {

    getByKeys: (configKeys) => {
        return CommonHttpService.httpPost(URL_CONSTANT.Configuration.GetConfigurations, configKeys);
    },
    getApplicationConfigurations: () => {
        return CommonHttpService.httpGet(URL_CONSTANT.Configuration.GetApplicationConfigurations);
    },
    getMiscConfigurations: () => {
        return CommonHttpService.httpGet(URL_CONSTANT.MiscConfiguration.GetMiscConfigurations);
    },
    updateMiscConfigurations: (data) => {
        return CommonHttpService.httpPut(URL_CONSTANT.MiscConfiguration.UpdateMiscConfigurations, data);
    },
    getAllMarketingContents: () => {
        return CommonHttpService.httpGet(URL_CONSTANT.MarketingContent.GetAllMarketingContents);
    },
    updateMarketingContent: (data) => {
        return CommonHttpService.httpPut(URL_CONSTANT.MarketingContent.UpdateMarketingContent, data);
    },
    isClientExist: (portcqClientId) => {
        return CommonHttpService.httpGet(`${URL_CONSTANT.Configuration.IsClientExist}?portcqClientId=${portcqClientId}`);
    },
    decryptQueryString: (data) => {
        return CommonHttpService.httpPost(URL_CONSTANT.Configuration.DecryptQueryString, data);
    }
}