import { SET_CLIENT_NAME, SET_CLIENT_CODE } from '../../constants/ActionConstants';

const InitialState = {};
export const Client = (state = InitialState, action) => {
    const { type, data } = action;

    switch (type) {
        case SET_CLIENT_NAME:
            return { ...state, clientName: data.clientName };
        case SET_CLIENT_CODE:
            return { ...state, clientCode: data.clientCode };
        default:
            return state;
    }
}