import React from 'react';
import { FaInfoCircle } from "react-icons/fa";
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { DATA_CONSTANT } from '../../../constants/DataConstant';
import './InputControl.css'

const InfoToolTip = ({
    helpText, // Displays information tooltip
    helpPlacement, // Placement for tooltip
    className, //Classname for tooltip
    ...props
}) => {
    const style = {

        marginLeft: '0.5rem',
        cursor: 'pointer'
    };
    return (
        <OverlayTrigger
            // trigger="click" -- By default it works for hover and focus. To manage click event we can pass trigger
            placement={helpPlacement}
            delay={{ show: 250, hide: 400 }}
            overlay={
                <Tooltip id="overlay-InputCheck">
                    {helpText}
                </Tooltip>
            }
            {...props}
        >
            <i style={style} id="info-icon" className={className}><FaInfoCircle /></i>
        </OverlayTrigger>
    )
};
InfoToolTip.defaultProps = {
    type: "text",
    className: "",
    helpPlacement: "right"
}
InfoToolTip.propTypes = {
    helpText: PropTypes.string.isRequired,
    helpPlacement: PropTypes.oneOf(DATA_CONSTANT.TooltipPlacement),
}
export default InfoToolTip;

