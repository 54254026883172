import "bootstrap/dist/css/bootstrap.css";
import React from "react";
import ReactDOM from "react-dom";
import registerServiceWorker from "./registerServiceWorker";
import withClearCache from './ClearCache';
import MainComponent from './main';

const rootElement = document.getElementById("root");

const RootComponentWithClearCache = withClearCache(MainComponent);

ReactDOM.render(

    <RootComponentWithClearCache />,
    rootElement
);

registerServiceWorker();
