export const ConfigRoutesConstants = {
    configurationRouteList: [{
        id: 1,
        routeName: 'configurations',
        routeUrl: '/configurations',
        layoutRequired: false,
        loginRequired: true,
        allowDocumentUpload: false,
        componentPath: './pages/Configurations/ConfigurationsPage',
        exact: true, linkDisplay: false,
        title: "Configurations"
    },
    {
        id: 2,
        routeName: 'terminology',
        routeUrl: '/configurations/terminology',
        layoutRequired: false,
        loginRequired: true,
        allowDocumentUpload: false,
        componentPath: './pages/Configurations/TerminologyPage',
        exact: false, linkDisplay: true,
        title: "Terminology"
    },
    {
        id: 3,
        routeName: 'misc',
        routeUrl: '/configurations/misc',
        layoutRequired: false,
        loginRequired: true,
        allowDocumentUpload: false,
        componentPath: './pages/Configurations/ConfigurationsPage',
        exact: false, linkDisplay: true,
        title: "Misc Configurations"
    },
    {
        id: 4,
        routeName: 'jobtypes',
        routeUrl: '/configurations/jobtypes',
        layoutRequired: false,
        loginRequired: true,
        allowDocumentUpload: false,
        componentPath: './pages/Configurations/JobTypesConfigurationPage',
        exact: false, linkDisplay: true,
        title: "Job Types"
    },
    {
        id: 5,
        routeName: 'rfqsteps',
        routeUrl: '/configurations/rfqsteps',
        layoutRequired: false,
        loginRequired: true,
        allowDocumentUpload: false,
        componentPath: './pages/Configurations/RFQStepsConfigurationPage',
        exact: false, linkDisplay: true,
        title: "RFQ Steps"
    },
    {
        id: 6,
        routeName: 'shippingmethods',
        routeUrl: '/configurations/shippingmethods',
        layoutRequired: false,
        loginRequired: true,
        allowDocumentUpload: false,
        componentPath: './pages/Configurations/ShippingMethodsConfigurationPage',
        exact: false, linkDisplay: true,
        title: "Shipping Methods"
    },
    {
        id: 7,
        routeName: 'marketingcontent',
        routeUrl: '/configurations/marketingcontent',
        layoutRequired: false,
        loginRequired: true,
        allowDocumentUpload: false,
        componentPath: './pages/Configurations/MarketingContentPage',
        exact: false, linkDisplay: true,
        title: "Marketing Content"
    },
    {
        id: 8,
        routeName: 'checklist',
        routeUrl: '/configurations/checklist',
        layoutRequired: false,
        loginRequired: true,
        allowDocumentUpload: false,
        componentPath: './pages/Configurations/CheckListConfigurationPage',
        exact: false, linkDisplay: true,
        title: "Checklist"
    }

        //,{
        //    id: 3,
        //    routeName: 'marketing',
        //    routeUrl: '/configurations/marketing',
        //    layoutRequired: false,
        //    loginRequired: true, allowDocumentUpload: false,
        //    componentPath: './pages/Configurations/TerminologyPage',
        //    exact: false, linkDisplay: true,
        //    title: "Marketing"
        //}
    ]
}