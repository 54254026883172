import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { trackPromise } from "react-promise-tracker";
import { connect } from "react-redux";
import { MdCancel } from "react-icons/md";
import './AssemblyChat.css';
import InputTextArea from "../ControlComponents/Input/InputTextArea";
import { SendButton } from "../ControlComponents/InputButton/InputButton";
import { AssemblyChatService } from "../../services/AssemblyChatService";
import { OIDCService } from "../../constants/UserManager";
import { Areas } from "../../constants/Areas";
import { LoadingWrapper } from "../CommonComponents/LoadingWrapper";
import { LoadingSpiner } from "../CommonComponents/LoadingSpiner";

const AssemblyChat = ({ assemblyId, showChat }) => {

    const [chatMessage, setChatMessage] = useState('');
    const [assemblyChatData, setAssemblyChatData] = useState([]);
    const loaderElement = useRef(null);
    const scrollElement = useRef(null);

    useEffect(() => {
        trackPromise(getAssemblyChat(), Areas.assemblyChat);
    }, []);

    useEffect(() => {
        if (assemblyChatData.length > 0 && scrollElement.current) {
            scrollElement.current.scrollTop = scrollElement.current.scrollHeight;
        }

    }, [assemblyChatData]);

    const getAssemblyChat = async () => {
        let user = OIDCService.getUser();
        let assemblyChatResponse = await AssemblyChatService.getAssemblyChat(assemblyId, user?.profile?.Email);
        if (assemblyChatResponse?.data) {
            setAssemblyChatData(assemblyChatResponse.data.chatNotes);
        }
    }

    const handleCancel = () => {
        if (showChat) {
            showChat(false);
        }
    }

    const handelChange = (event) => {
        setChatMessage(event.target.value);
    }

    const onKeyPressHandler = event => {
        if (event.key === '\n' && event.ctrlKey) {
            event.preventDefault();
            handelSendClick();
        }
    }

    const handelSendClick = () => {
        if (chatMessage?.trim()) {
            let user = OIDCService.getUser();

            let saveMessageObject = {
                ChatNotesId: assemblyId,
                LoginUserEmail: user?.profile?.Email,
                Comments: chatMessage.trim(),
                IsInternal: false
            }

            trackPromise((async () => {

                let assemblyChatResponse = await AssemblyChatService.saveAssemblyChatMessage(saveMessageObject);
                if (assemblyChatResponse?.data) {
                    setChatMessage('');
                    setAssemblyChatData(assemblyChatResponse.data.chatNotes);
                }

            })(), Areas.assemblyChat);
        }
    }

    return (
        <Container fluid className="wrapper">
            <LoadingWrapper loaderRef={loaderElement}>
                <LoadingSpiner area={Areas.assemblyChat} parentContainer={loaderElement} />
                <div className="text-center popover-title">
                    <h6>Assembly Discussion <MdCancel className="svg-r icon-cursor text-right" onClick={handleCancel} /></h6>
                </div>
                <Row className="chat-content" ref={scrollElement}>
                    <Col md={12} sm={12} xs={12}>
                        {
                            assemblyChatData.map((chatData, index) => {
                                return (
                                    <div key={index}>
                                        <div className="small text-muted chat-date">-----&nbsp; &nbsp; {chatData.displayDateText} &nbsp; &nbsp; -----</div>
                                        {
                                            chatData.chatNoteDetails.map((message, index1) => {

                                                if (!message.isLoginUser) {
                                                    return (
                                                        <div key={`${index}-${index1}`} className="m-b-10 text-align-right">
                                                            <div className="small text-muted word-break">
                                                                {message.userName}, {message.createdTime}
                                                            </div>
                                                            <div className="msg-box chat-receiver word-break">
                                                                {message.comments}
                                                            </div>
                                                        </div>
                                                    )

                                                } else {

                                                    return (
                                                        <div key={`${index}-${index1}`} className="text-right m-b-10">
                                                            <div className="small text-muted word-break">{message.userName}, {message.createdTime}</div>
                                                            <div className="msg-box chat-sender word-break">{message.comments}</div>
                                                        </div>
                                                    )
                                                }
                                            })
                                        }
                                    </div>
                                )
                            })
                        }
                        
                    </Col>
                </Row>
                <Row>
                    <Col md={10} sm={10} xs={10}>
                        <InputTextArea
                            name="ChatMessage"
                            rows={2}
                            maxLength="4000"
                            value={chatMessage}
                            placeholder="Write a message"
                            onChange={(event) =>
                                handelChange(event)
                            }
                            onKeyPress={onKeyPressHandler}
                        ></InputTextArea>
                    </Col>
                    <Col md={2} sm={2} xs={2} className="align-middle">
                        <SendButton className="float-right" isDisabled={chatMessage.trim() ? false : true} handleClick={handelSendClick} />
                    </Col>
                </Row>
            </LoadingWrapper>
        </Container>
    );
};

const mapStateToProps = (state) => ({
    assemblyId: state.RFQDetails?.rfqDetails?.assemblyId
});

export default connect(mapStateToProps, null)(AssemblyChat);