import { SET_RFQ_DETAILS, SET_BOMID, SET_IS_CUSTOMQUOTE_REQUIRES, SET_EXTERNAL_USERID } from '../../constants/ActionConstants';

export const setRFQDetails = (rfqDetails) => ({
    type: SET_RFQ_DETAILS,
    data: { rfqDetails: rfqDetails }
});

export const setBOMId = (bomId) => ({
    type: SET_BOMID,
    data: { bomId: bomId || 0 }
});

export const setIsCustomQuoteRequired = (isCustomQuoteRequires) => ({
    type: SET_IS_CUSTOMQUOTE_REQUIRES,
    data: { isCustomQuoteRequires: isCustomQuoteRequires || false }
});

export const setExternalUserId = (externalUserId) => ({
    type: SET_EXTERNAL_USERID,
    data: { externalUserId: externalUserId }
});
