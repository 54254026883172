import React, { createContext, lazy, Suspense, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import PrivateRoute from '../src/components/Routes/PrivateRoute';
import PublicRoute from '../src/components/Routes/PublicRoute';
import { LoadingSpiner } from "./components/CommonComponents/LoadingSpiner";
import { ConfigRoutesConstants } from './constants/ConfigRoutesConstants';
import { DATA_CONSTANT } from './constants/DataConstant';
import ConfigurationsLayout from './layouts/ConfigurationsLayout';
import RfqProcessLayout from './layouts/RfqProcessLayout';
import { RouteService } from './services/RouteService';
import { CommonService } from "./services/CommonService";
import { clearJobType, clearStore, resetCompletedSteps, setClientCode } from './redux/actions/index';
import { trackPromise } from 'react-promise-tracker';

export let RouteDataContext = createContext({});

const Routes = ({ isCustomQuote, isViewMode, isBomAnalysis, clientInfo, setClientInfo, resetReduxData }) => {
    const menuItem = ConfigRoutesConstants.configurationRouteList;

    const [routeList, setRouteList] = useState({ noLayoutRoutes: [], rfqProcessLayoutRoutes: [], configurationsLayoutRoutes: [] });

    const clientCode = clientInfo.clientCode;
    const urlClientCode = DATA_CONSTANT.IsCustomDomainClient ? "" : `/${DATA_CONSTANT.ClientCode}`;
    const location = useLocation();
    const history = useHistory();

    useEffect(() => {

        if (!DATA_CONSTANT.IsCustomDomainClient && clientCode != DATA_CONSTANT.ClientCode) {
            setClientInfo(DATA_CONSTANT.ClientCode);

            if (clientCode) {
                resetReduxData();
                history.push(CommonService.getRoutePath(""));
            }
        }

        trackPromise(getRouteList());

    }, [])

    useEffect(() => {
        checkIsCustomQuote();
        if (isViewMode && location.pathname != `${urlClientCode}/${DATA_CONSTANT.routes.quoteHistory}` && !location.pathname.includes("configurations")) {
            if (isBomAnalysis && location.pathname != `${urlClientCode}/${DATA_CONSTANT.routes.reviewbom}`) {
                history.push(CommonService.getRoutePath(DATA_CONSTANT.routes.reviewbom));

            }
            else if (!isBomAnalysis && location.pathname != `${urlClientCode}/${DATA_CONSTANT.routes.checkout}`) {
                history.push(CommonService.getRoutePath(DATA_CONSTANT.routes.checkout));
            }
        }
    }, [location])

    const checkIsCustomQuote = () => {
        if (isCustomQuote && location.pathname != `${urlClientCode}/${DATA_CONSTANT.routes.quoteHistory}` && location.pathname != `${urlClientCode}/${DATA_CONSTANT.routes.customQuote}`) {
            history.push(CommonService.getRoutePath(DATA_CONSTANT.routes.customQuote));
        }
    }

    const [routesForAllowDocumentUpload, setRoutesForAllowDocumentUpload] = useState();

    const getRouteList = async () => {
        let result = await RouteService.RouteList();
        result.routeList.map((obj) => {
            obj.routeUrl = `${urlClientCode}${obj.routeUrl}`
        });
        menuItem.map((obj) => {
            obj.routeUrl = `${urlClientCode}${obj.routeUrl}`
        });
        if (result) {
            let routesForContext = []
            result.routeList.map((obj) => {
                var route = {
                    RouteUrl: obj.routeUrl,
                    AllowDocumentUpload: obj.allowDocumentUpload,
                    AllowAssemblyDiscussion: obj.allowAssemblyDiscussion
                }
                routesForContext.push(route);
            });
            setRoutesForAllowDocumentUpload(routesForContext);
            let noLayoutRoutes = result.routeList ? result.routeList.filter((route) => (!route.layoutRequired)) : [];
            let rfqProcessLayoutRoutes = result.routeList ? result.routeList.filter((route) => (route.layoutRequired)) : [];
            let configurationsLayoutRoutes = menuItem || [];
            setRouteList({
                noLayoutRoutes: noLayoutRoutes,
                rfqProcessLayoutRoutes: rfqProcessLayoutRoutes,
                configurationsLayoutRoutes: configurationsLayoutRoutes
            });
        }
    }

    return (
        <Suspense fallback={<LoadingSpiner />}>
            <RouteDataContext.Provider value={routesForAllowDocumentUpload}>
                <Switch>
                    {routeList?.noLayoutRoutes.length > 0 &&
                        routeList.noLayoutRoutes.map((route) => {
                            if (route.loginRequired) {
                                return <PrivateRoute exact={true} path={route.routeUrl} component={lazy(() => import(`${route.componentPath}`))} title={route.routeName} key={`route_${route.routeName}`} />
                            } else {
                                return <PublicRoute exact={true} path={route.routeUrl} allowDocumentUpload={route.allowDocumentUpload} component={lazy(() => import(`${route.componentPath}`))} title={route.routeName} key={`route_${route.routeName}`} />
                            }
                        })
                    }
                    {routeList?.rfqProcessLayoutRoutes.length > 0 ?

                        routeList.rfqProcessLayoutRoutes.map((route) => {
                            if (route.loginRequired) {
                                return (
                                    <PrivateRoute exact={true} path={route.routeUrl} component={lazy(() => import(`${route.componentPath}`))}
                                        title={route.routeName} key={`route_${route.routeName}`} layout={RfqProcessLayout} />
                                )
                            } else {
                                return (
                                    <PublicRoute exact={true} path={route.routeUrl} allowDocumentUpload={route.allowDocumentUpload}
                                        component={lazy(() => import(`${route.componentPath}`))} title={route.routeName} key={`route_${route.routeName}`}
                                        layout={RfqProcessLayout} />
                                )
                            }
                        })


                        : <></>
                    }
                    {routeList?.configurationsLayoutRoutes.length > 0 ?
                        routeList.configurationsLayoutRoutes.map((route) => {
                            return (
                                <PrivateRoute exact={route.exact} path={route.routeUrl} component={lazy(() => import(`${route.componentPath}`))}
                                    title={route.title} key={`route_${route.routeName}`} layout={ConfigurationsLayout} />
                            )
                        })

                        : <></>
                    }
                </Switch>
                <Route path={`${urlClientCode}/configurations`} exact={true} render={props => (
                    <Redirect to={CommonService.getRoutePath("configurations/terminology")} />)} />

            </RouteDataContext.Provider>
        </Suspense>
    );
}

const mapStateToProps = state => ({
    isCustomQuote: state.RFQDetails?.isCustomQuoteRequires,
    isViewMode: state.RFQDetails?.rfqDetails?.isViewMode,
    isBomAnalysis: state.RFQDetails?.rfqDetails?.isBomAnalysis,
    clientInfo: state.Client
});

const mapDispatchToProps = (dispatch) => ({
    setClientInfo: (clientCode) => dispatch(setClientCode(clientCode)),
    resetReduxData: () => {
        dispatch(clearStore());
        dispatch(resetCompletedSteps());
        dispatch(clearJobType({}));
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(Routes);