import React from 'react';
import { usePromiseTracker } from "react-promise-tracker";
import BeatLoader from "react-spinners/BeatLoader";
import './LoadingSpinner.css'

export const LoadingSpiner = (props) => {
    const { promiseInProgress } = usePromiseTracker({ area: props.area });
    const parentContainer = props.area ? props.parentContainer?.current : null;
    let loaderClass = null;
    if (promiseInProgress) {
        if (parentContainer) {
            loaderClass = "componentwise-loader";
        } else {
            loaderClass = "full-body-loader";
        }
    } else {
        if (parentContainer) {
            parentContainer.classList.remove('cm-backdrop');
        } else {
            if (document.getElementsByClassName("overlay-box").length > 0) {
                document.getElementById("bodyContainer").remove();
            }
        }
    }

    return (
        promiseInProgress &&
        <>
            <div className={parentContainer ? "cm-backdrop" : "cm-backdrop-full-body"}></div>
            <div className={`${loaderClass} p-0 beat-loader`} >
                <div className="text-center">
                    <div className="loading-image"></div>
                    <BeatLoader
                        size={20}
                        margin={10}
                        color={`var(--loaderDotsColor)`}
                        loading={promiseInProgress}
                    />
                </div>
            </div>
        </>
    )
};