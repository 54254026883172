import React, { memo } from "react";
import { Prompt } from "react-router-dom";
import { JARGON_CONSTANT } from "../../constants/JargonConstant";

const NavigationBlocker = ({ navigationBlocked }) => {
    if (navigationBlocked) {
        window.onbeforeunload = () => true;
    }
    else {
        window.onbeforeunload = null;
        window.onunload = null;
    }

    return (
        navigationBlocked && <Prompt when={navigationBlocked} message={JARGON_CONSTANT.jargonObject.NavigationBlockMessage} />
    )
}
export default memo(NavigationBlocker);
