import { CommonHttpService } from './CommonHttpService';
import { URL_CONSTANT } from '../constants/UrlConstant';

export const JargonService = {
    GetJargonList: () => {
        return CommonHttpService.httpGet(URL_CONSTANT.GetJargonList);
    },
    GetJargonListFromConfigurations: () => {
        return CommonHttpService.httpGet(URL_CONSTANT.GetJargonListFromConfigurations);
    },
    SaveUpdatedData: (data) => {
        return CommonHttpService.httpPost(URL_CONSTANT.SaveUpdatedData, data);
    }
}